import React, { useEffect, useState } from "react";
import { fetchCarById } from "../Api/Cars";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Map from "../Components/Map";
import LoadingPage from "../Components/LoadingPage";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function CarById() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [car, setCar] = useState("");
  const [loading, setLoading] = useState(true);
  const getCarData = async () => {
    try {
      setLoading(true);
      const response = await fetchCarById(id);
      console.log(response);
      setCar(response.data);
    } catch (err) {
      console.error("get car data err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCarData();
  }, []);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Cars"), url: "/dashboard/cars" },
    { name: `${t("Car")} ${id}`, url: `${id}` },
  ];

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="flex flex-col gap-3">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="flex flex-col border border-[#9ea5ad] shadow-lg bg-white rounded-[0.25rem] p-[20px] text-[13px]">
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver id")} :</strong> {car?.driver_id}
        </div>
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver name")} :</strong> {car?.driver_name}
        </div>
      </div>
      <div className="flex flex-col border border-[#9ea5ad] shadow-lg bg-white rounded-[0.25rem]">
        <div className="flex items-center gap-1 w-full whitespace-nowrap p-[20px] text-[13px]">
          <strong>{t("Car location")} :</strong>
        </div>
        <Map langitude={car?.lng} latitude={car?.lat} />
      </div>
    </div>
  );
}

export default CarById;
