import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin, isSuper } from "./api";

export const laundryByAdmin = async (page_number ) => {
  try {
    const response = isSuper ? await apiAdmin.get(`/api/LaundryByAdmin?page=${page_number}`) : await apiAdmin.get(`/api/LaundryByAdmin`);
    return response.data;
  } catch (error) {
    console.log("laundry by admin err : ", error);
  }
};

export const updateProductPriceInLaundry = async (laundry_id,laundry_item_id,order_type_id,service_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/update-Laundryprice?laundry_id=${laundry_id}&id=${laundry_item_id}&order_type_id=${order_type_id}&service_id=${service_id}`,data);
    return response.data;
  } catch (error) {
      console.log("update product price err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const fetchLaundries = async () => {
  try {
    const response = await apiAdmin.get(`/api/launderies`);
    return response.data;
  } catch (error) {
    console.log("fetch laundries err : ", error);
  }
};

export const fetchCarsByLaundry = async (laundry_id,page = 1) => {
  try {
    const response = await apiAdmin.get(`/api/CarByLaundry?laundry_id=${laundry_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch cars by laundry err : ", error);
  }
};

export const fetchDriversByLaundry = async (laundry_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/getDriversByLaundryId?laundry_id=${laundry_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch drivers by laundry err : ", error);
  }
};

export const fetchOrdersByLaundry = async (laundry_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/OrderByLaundryId?laundry_id=${laundry_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch orders by laundry err : ", error);
  }
};

// super admin

export const postLaundry = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/Laundry`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
      console.log("post laundry err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundry = async (laundry_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/Laundry-update?id=${laundry_id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
      console.log("update laundry err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundryStatus = async (laundry_id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateStatusLaundery?id=${laundry_id}`);
    return response.data;
  } catch (error) {
      console.log("update laundry status err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundryUgentStatus = async (laundry_id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateUrgent?id=${laundry_id}`);
    return response.data;
  } catch (error) {
      console.log("update laundry ugent status err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundryCollectionStatus = async (laundry_id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateCollection?id=${laundry_id}`);
    return response.data;
  } catch (error) {
      console.log("update collection status err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const fetchLaundryById = async (laundry_id) => {
  try {
    const response = await apiAdmin.get(`/api/laundry?id=${laundry_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch laundry by id err : ", error);
  }
};


export const fetchUsers = async (status_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/Filter-users?status_id=${status_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch users by status id err : ", error);
  }
};

export const fetchAndSearchForPersons = async (query,status_id,page) => {
  try {
    const response = await apiAdmin.get(`/api/search-users?name=${query}&status_id=${status_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("search for user err : ", error);
  }
};


export const updateLaundryMedia = async (laundry_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/laundry-media?laundry_id=${laundry_id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log(`update laundry ${laundry_id} media err : `, error);
  }
};

export const deleteLaundryMediaItem = async (laundry_media_id) => {
  try {
    const response = await apiAdmin.delete(`api/laundry-media?laundry_media_id=${laundry_media_id}`);
    return response.data;
  } catch (error) {
    console.log(`delete media number ${laundry_media_id} err : `, error);
  }
};