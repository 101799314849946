import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import PopupProvider from './Context/PopupContext';
import DashboardProvider from './Context/DashboardContext';
import "./translations/i18n"


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered:', registration);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

const contextClass = {
  success: "bg-gradient-to-br from-[#78bcd0] to-[#00238c] text-white",
  error: "bg-gradient-to-br from-[#d07878] to-[#e30b0b] text-white",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <PopupProvider>
        <DashboardProvider>
          <ToastContainer toastClassName={(context) =>
          contextClass[context?.type || "default"] +
          " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
        }/>
          <App />
        </DashboardProvider>
      </PopupProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
