import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'leaflet/dist/leaflet.css';
import "react-datepicker/dist/react-datepicker.css";
import Admin from './Pages/Admin';
import Dashboard from './Pages/Dashboard';
import MainDashPage from './Pages/MainDashPage';
import Prices from './Pages/Products';
import Orders from './Pages/Orders';
import OrderDetails from './Pages/OrderDetails';
import Reports from './Pages/Reports';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDashboard } from './Context/DashboardContext';
import { useEffect } from 'react';
import { adminToken, superAdminToken } from './Api/api';
import UpdateOrder from './Pages/UpdateOrder';
import UpdateProductPrice from './Pages/UpdateProductPrice';
import Laundries from './Pages/Laundries';
import AddLaundry from './Pages/AddLaundry';
import UpdateLaundry from './Pages/UpdateLaundry';
import Admins from './Pages/Admins';
import AddNewAdmin from './Pages/AddNewAdmin';
import AddNewDriver from './Pages/AddNewDriver';
import Drivers from './Pages/Drivers';
import UpdateDriver from './Pages/UpdateDriver';
import LaundryById from './Pages/LaundryById';
import Cars from './Pages/Cars';
import AddNewCar from './Pages/AddNewCar';
import UpdateCar from './Pages/UpdateCar';
import Users from './Pages/Users';
import Advertisements from './Pages/Advertisements';
import AddNewAdvertisement from './Pages/AddNewAdvertisement';
import UpdateAdvertisement from './Pages/UpdateAdvertisement';
import CarById from './Pages/CarById';
import AddNewOrder from './Pages/AddNewOrder';
import Notifications from './Pages/Notifications';
import Items from './Pages/Items';
import AddNewItem from './Pages/AddNewItem';
import UpdateItem from './Pages/UpdateItem';
import Services from './Pages/Services';
import AddNewService from './Pages/AddNewService';
import UpdateService from './Pages/UpdateService';
import UserById from './Pages/UserById';


function App() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const {dir,setDir} = useDashboard()
    useEffect(() => {
      document.body.dir = dir;
      setDir(lng === "Arabic" ? "rtl" : "ltr");
    }, [dir, lng]);
   
    const router = createBrowserRouter([
    {
      path: "/",
      element: !adminToken || !superAdminToken ? <Admin/> : <Navigate to="/dashboard" />,
    },
    {
      path: "/dashboard",
      element: adminToken || superAdminToken ? <Dashboard /> : <Navigate to="/" />, children: [
        {index : true , element : <MainDashPage/>},
        {path: "laundries", element: superAdminToken ? <Laundries /> : <Navigate to="/"/>},
        {path: "laundries/addNewLaundry", element: superAdminToken ? <AddLaundry /> : <Navigate to="/"/>},
        {path: "laundries/:id", element: superAdminToken ? <LaundryById /> : <Navigate to="/"/>},
        {path: "laundries/:id/updateLaundry", element: superAdminToken ? <UpdateLaundry /> : <Navigate to="/"/>},
        {path: "admins", element: superAdminToken ? <Admins /> : <Navigate to="/"/>},
        {path: "admins/addNewAdmin", element: superAdminToken ? <AddNewAdmin /> : <Navigate to="/"/>},
        {path: "users", element:  <Users/> },
        {path: "users/:id", element:  <UserById/> },
        {path: "drivers", element:  <Drivers /> },
        {path: "drivers/addNewDriver", element:  <AddNewDriver />  },
        {path: "drivers/:id", element:  <UpdateDriver />  },
        { path: "cars", element: <Cars />  },
        { path: "cars/:id", element: <CarById />  },
        {path: "cars/addNewCar", element: <AddNewCar />  },
        {path: "cars/:id/updateCar", element: <UpdateCar />  },
        { path: "advertisements", element: <Advertisements /> },
        { path: "advertisements/addNewAdvertisement", element: <AddNewAdvertisement /> },
        {path: "advertisements/:id/updateAdvertisement", element:  <UpdateAdvertisement />},
        {path : "notifications" , element : <Notifications/>},
        {path : "items" , element : <Items/>},
        {path : "items/addNewItem" , element : <AddNewItem/>},
        {path : "items/:id/updateItem" , element : <UpdateItem/>},
        {path : "services" , element : <Services/>},
        {path : "services/addNewService" , element : <AddNewService/>},
        {path : "services/:id/updateService" , element : <UpdateService/>},
        {path : "orders" , element : <Orders/>},
        {path : "orders/:id" , element : <OrderDetails/>},
        {path : "orders/addNewOrder" , element : <AddNewOrder/>},
        {path : "orders/:id/updateOrder" , element : <UpdateOrder/>},
        {path : "products" , element : <Prices/>},
        {path : "products/:itemId/:typeId/:serviceId" , element : <UpdateProductPrice/>},
        {path : "reports" , element : <Reports/>},
      ]
    },
      ]);
  return (
    <div className='text-MainText font-MainFont'>
      <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
      <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
}

export default App;
