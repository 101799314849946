import React, { useState, useEffect } from "react";
import OutterTable from "../Components/OutterTable";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { useTranslation } from "react-i18next";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import InnerTable from "../Components/InnerTable";
import { FaEye } from "react-icons/fa";
import Pagination from "../Components/Pagination";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import BreadcrumbPath from "../Components/BreadcrumbPath";
import { fetchNotifacations } from "../Api/Notifactions";
function Notifications() {
  const [moreOpen, setMoreOpen] = useState({});
  const { convertToRead, updateNotifications } = useDashboard();
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const getNotifications = async (page, perPage) => {
    try {
      setNotificationsLoading(true);
      const response = await fetchNotifacations(page, perPage);
      console.log(response);
      setNotifications(response.data.notifications);
      setCurrentPage(response?.data?.pagination?.current_page);
      setTotalPages(response?.data?.pagination?.last_page);
      setItemPerPage(response?.data?.pagination?.per_page);
      setTotal(response?.data?.pagination?.total);
      setFrom(response?.data?.pagination?.from);
      setTo(response?.data?.pagination?.to);
    } catch (err) {
      console.error("getNotifications err : ", err);
    } finally {
      setNotificationsLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const { t } = useTranslation();
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Notifications"), url: "/notifications" },
  ];
  useEffect(() => {
    getNotifications(currentPage, 20);
  }, [updateNotifications]);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("Type")} />
              <MainHeadTableItem
                content={t("Title")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Body")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Order Id")}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem
                content={t("Read at")}
                className={`max-md:hidden w-[150px]`}
              />
              <MainHeadTableItem
                content={t("...")}
                className={`max-md:hidden w-[70px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {notificationsLoading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 7 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : notifications.length > 0 ? (
              notifications.map((item) => (
                <>
                  <tr key={item.id} className="">
                    <MainBodyTableItem
                      content={item.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={item.type} />
                    <MainBodyTableItem
                      content={item?.title}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={item?.body}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={item?.order_id}
                      className={`max-md:hidden w-[100px]`}
                    />
                    <MainBodyTableItem
                      content={
                        item?.read_at === null ? t("Not yet") : item.read_at
                      }
                      className={`max-md:hidden w-[150px]`}
                    />
                    <MainBodyTableItem
                      content={
                        item?.read_at === null && (
                          <FaEye
                            onClick={() => convertToRead(item.id)}
                            size={25}
                            className="mx-auto cursor-pointer hover:text-PrimaryColor duration-300"
                          />
                        )
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(item.id)}
                        >
                          {moreOpen[item.id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[item.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={item.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Type")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={item.type} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Title")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={item?.title} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Body")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={item?.body} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Order Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={item?.order_id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Read at")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            item?.read_at === null ? t("Not yet") : item.read_at
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("...")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            item?.read_at === null && (
                              <FaEye
                                onClick={() => convertToRead(item.id)}
                                size={25}
                                className="mx-auto cursor-pointer hover:text-PrimaryColor duration-300"
                              />
                            )
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No notifications available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Notifications;
