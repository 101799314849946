import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { fetchAndSearchForPersons } from "../Api/Laundry";
import Pagination from "../Components/Pagination";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function Admins() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);

  const search = async () => {
    getAdminsData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getAdminsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAndSearchForPersons(query, 1, currentPage);
      console.log(response);
      setAdmins(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getAdminsData();
  }, [currentPage]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Admins"), url: "/admins" },
  ];

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="flex md:items-center max-md:flex-col gap-3">
        {/* <Link
          to={`addNewAdmin`}
          className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[114px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
        >
          {t("Add new admin")}
        </Link> */}
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by admin name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                {t("Id")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                {t("Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Email")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px] md:hidden"></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 3 }).map((_, inIndex) => (
                    <td key={inIndex}>
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : admins.length > 0 ? (
              admins.map((admin) => (
                <>
                  <tr key={admin.id}>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                      {admin.id}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                      {admin.name}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {admin.email}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(admin.id)}
                      >
                        {moreOpen[admin.id] ? (
                          <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                        ) : (
                          <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                        )}
                      </span>
                    </td>
                  </tr>
                  <InnerTable openStatus={moreOpen[admin.id]}>
                    <tbody>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Id")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {admin.id}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Name")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {admin.name}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Email")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {admin.email}
                        </td>
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No admins available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Admins;
