import React, { useEffect, useState } from "react";
import { GiWashingMachine } from "react-icons/gi";
import { IoIosWater } from "react-icons/io";
import { FaClockRotateLeft } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";
import { SiFuturelearn } from "react-icons/si";
import MainDashPageItem from "../Components/MainDashPageItem";
import { fetchTotal, isSuper } from "../Api/api";
import { fetchOrdersChart } from "../Api/Orders";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
import OrdersStatsChart from "../Components/OrdersStatsChart";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import CustomSelect from "../Components/CustomSelect";
import CustomOption from "../Components/CustomOption";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function MainDashPage() {
  const { t } = useTranslation();
  const { dir, setToday, updateLaundriesData, setUpdateLaudriesData } =
    useDashboard();
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [ordersStats, setOrdersStats] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusId, setStatusId] = useState(1);

  const getTotalData = async () => {
    try {
      setDataLoading(true);
      const response = await fetchTotal();
      console.log(response.data[0]);
      setData(response.data[0]);
    } catch (err) {
      console.error("get total data err : ", err);
    } finally {
      setDataLoading(false);
    }
  };

  const getOrdersChartData = async (start, end, status) => {
    try {
      setOrdersLoading(true);
      const response = await fetchOrdersChart(
        start ? format(start, "yyyy-MM-dd") : start,
        end ? format(end, "yyyy-MM-dd") : end,
        status
      );
      console.log(response.data);
      if (statusId === 1) {
        setOrdersStats(
          response.data.map((item) => ({
            label1: item.date,
            label2: item.count,
          }))
        );
      } else if (statusId === 2) {
        setOrdersStats(
          response.data.map((item) => ({
            label1: item.month,
            label2: item.count,
          }))
        );
      }
      if (statusId === 3) {
        setOrdersStats(
          response.data.map((item) => ({
            label1: item.year,
            label2: item.count,
          }))
        );
      }
    } catch (err) {
      console.error("get orders chart data err : ", err);
    } finally {
      setOrdersLoading(false);
    }
  };

  useEffect(() => {
    !isSuper && getOrdersChartData(startDate, endDate, statusId);
  }, [dir, updateLaundriesData]);

  useEffect(() => {
    getTotalData();
  }, []);

  const getDateFormat = (status) => {
    switch (status) {
      case 1:
        return "dd/MM/yyyy";
      case 2:
        return `MM/yyyy`;
      case 3:
        return "yyyy";
      default:
        return "dd/MM/yyyy";
    }
  };

  const getCustomHeader = (status) => {
    switch (status) {
      case 1:
        return {
          dateFormat: "dd MMMM yyyy",
        };
      case 2:
        return {
          dateFormat: `MMMM yyyy`,
          showMonthYearPicker: true,
          showFullMonthYearPicker: true,
        };
      case 3:
        return {
          dateFormat: "yyyy",
          showYearPicker: true,
        };
      default:
        return {};
    }
  };

  const breadcrumbPaths = [{ name: t("Home"), url: "/dashboard" }];

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="flex flex-wrap gap-3 max-md:flex-col">
        <MainDashPageItem
          onClick={() => setToday(true)}
          link={`/dashboard/orders`}
          content={t(`Orders for today`)}
          icon={<GiWashingMachine />}
          loading={dataLoading}
          number={data.orders_for_today}
        />
        <MainDashPageItem
          onClick={() => setToday(false)}
          link={`/dashboard/orders`}
          content={t(`Total orders`)}
          icon={<IoIosWater />}
          loading={dataLoading}
          number={data.totalOrders}
        />
        <MainDashPageItem
          onClick={() => setToday(false)}
          link={`/dashboard/orders`}
          content={t(`Pending orders`)}
          icon={<FaClockRotateLeft />}
          loading={dataLoading}
          number={data.pendingOrders}
        />
        <MainDashPageItem
          link={`/dashboard/cars`}
          content={t(`Cars in service`)}
          icon={<FaTruck />}
          loading={dataLoading}
          number={data.carservice}
        />
        <MainDashPageItem
          link={`/dashboard/orders`}
          content={t(`Income this month`)}
          icon={<SiFuturelearn />}
          loading={dataLoading}
          number={data.incomeThisMonth}
        />
      </div>
      {!isSuper && (
        <>
          <div className="text-MainText font-[500]">{t("Orders Chart")}</div>
          <div className="flex flex-wrap gap-3">
            <div className="flex flex-nowrap gap-3 max-md:w-full">
              <div className="max-md:basis-1/2 md:w-[270px] min-h-[38.34px] h-[38.34px]">
                <DatePicker
                  className="bg-white outline-none focus:ring-0 cursor-pointer min-w-full min-h-full font-semibold flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
                  selected={startDate}
                  placeholderText={t("Start Date")}
                  maxDate={subDays(new Date(), 1)}
                  onChange={(date) =>
                    statusId === 3
                      ? setStartDate(format(date, `yyyy-01-01`))
                      : setStartDate(format(date, "yyyy-MM-dd"))
                  }
                  dateFormat={getDateFormat(statusId)}
                  {...getCustomHeader(statusId)}
                />
              </div>
              <div className="max-md:basis-1/2 md:w-[270px] min-h-[38.34px] h-[38.34px]">
                <DatePicker
                  className="bg-white outline-none focus:ring-0 cursor-pointer w-full min-h-full font-semibold flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
                  selected={endDate}
                  placeholderText={t("End Date")}
                  onChange={(date) =>
                    statusId === 2
                      ? setEndDate(
                          format(date, `yyyy-MM-${format(new Date(), "dd")}`)
                        )
                      : statusId === 3
                      ? setEndDate(
                          format(date, `yyyy-${format(new Date(), "MM-dd")}`)
                        )
                      : setEndDate(format(date, "yyyy-MM-dd"))
                  }
                  maxDate={new Date()}
                  dateFormat={getDateFormat(statusId)}
                  {...getCustomHeader(statusId)}
                />
              </div>
            </div>
            <CustomSelect
              value={statusId}
              className={`md:min-w-[200px] md:max-w-[200px]`}
              onChange={(e) => setStatusId(parseInt(e.target.value))}
              children={
                <>
                  <CustomOption content={t("Daily")} value="1" />
                  <CustomOption content={t("Monthly")} value="2" />
                  <CustomOption content={t("Yearly")} value="3" />
                </>
              }
            />
            <button
              onClick={() => getOrdersChartData(startDate, endDate, statusId)}
              className="bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full md:min-w-[270px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
            >
              {t("Update")}
            </button>
          </div>
          <div className="max-w-[1116px] shadow-lg bg-white rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText focus:border-MainText p-[20px]">
            {ordersLoading ? (
              <Skeleton
                width={`100%`}
                className="min-h-[558px] max-md:min-h-[187px]"
              />
            ) : (
              <OrdersStatsChart ordersStats={ordersStats} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MainDashPage;
