import React from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useDashboard } from "../Context/DashboardContext";
import { lng } from "../Api/api";

function CustomSelect({ value, onChange, id, name, children, className }) {
  const { selectedLanguage } = useDashboard();
  return (
    <div
      className={`${className} relative min-h-[38.34px] w-fit bg-white cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300`}
    >
      <select
        className={`${
          selectedLanguage === "Arabic"
            ? "pr-[12px] pl-[24px]"
            : "pr-[24px] pl-[12px]"
        } w-full h-full py-[7.5px] cursor-pointer appearance-none outline-none focus:ring-0`}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
      >
        {children}
      </select>
      <span
        className={`${
          selectedLanguage === "Arabic" ? "left-[7px]" : "right-[7px]"
        } absolute top-1/2 transform -translate-y-1/2 pointer-events-none`}
      >
        <MdOutlineArrowDropDown size={20} />
      </span>
    </div>
  );
}

export default CustomSelect;
