import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

function Pagination({
  key,
  totalPages,
  currentPage,
  itemPerPage,
  handlePageClick,
  from,
  to,
  total,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex md:justify-between md:items-center max-md:flex-col-reverse max-md:gap-2">
      {totalPages > 1 && (
        <ReactPaginate
          key={key}
          initialPage={currentPage - 1}
          previousLabel={t("Prev")}
          nextLabel={t("Next")}
          breakLabel={"..."}
          pageCount={Math.ceil(totalPages)}
          perPage={itemPerPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          onPageChange={handlePageClick}
          containerClassName={"flex flex-wrap items-center gap-1"}
          activeLinkClassName={
            "py-[7.5px] px-[12px] cursor-default border-transparent hover:border-transparent bg-gradient-to-b from-[#78bcd0] to-[#00238c] text-white border-white rounded-[0.25rem]"
          }
          previousLinkClassName={`${
            currentPage === 1 && "hidden"
          } py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText   rounded-[0.25rem] duration-300`}
          nextLinkClassName={`${
            currentPage === totalPages && "hidden"
          } py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText   rounded-[0.25rem] duration-300`}
          pageLinkClassName={
            "py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText   rounded-[0.25rem] duration-300"
          }
          renderOnZeroPageCount={null}
        />
      )}
      {from && to && total && (
        <div className="font-semibold text-[13px]">
          {t("Showing")} {from} {t("to")} {to} {t("of")} {total} {t("items")}
        </div>
      )}
    </div>
  );
}

export default Pagination;
