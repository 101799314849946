import React, { useEffect, useState } from "react";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import Pagination from "../Components/Pagination";
import { Link, useParams } from "react-router-dom";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { usePopup } from "../Context/PopupContext";
import { fetchAllOrders } from "../Api/Orders";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { IoIosCloseCircle, IoMdMore } from "react-icons/io";
import CustomSelect from "./CustomSelect";
import { FaCheck, FaWindowClose } from "react-icons/fa";
import CustomOption from "./CustomOption";
import BreadcrumbPath from "./BreadcrumbPath";

function OrderData({ laundryByIdPage }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { setMakeDecisionOpen, setAction, setType, setItemId } = usePopup();
  const { orderStatusId, setOrderStatusId, today, setToday, updateOrders } =
    useDashboard();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const [query, setQuery] = useState("");
  const search = async (e) => {
    getOrders();
  };
  // Function to toggle the open state for a specific order
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getOrders = async (orderStatusId, currentPage) => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAllOrders(
        orderStatusId,
        today === "1" ? 1 : "",
        query,
        currentPage,
        laundryByIdPage && id
      );
      console.log(response.data);
      setOrders(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get orders for today err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getOrders(orderStatusId, currentPage);
  }, [today, orderStatusId, updateOrders, currentPage]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Orders"), url: "/orders" },
  ];

  return (
    <div
      className={`${
        !laundryByIdPage && "max-md:min-h-screen"
      } flex gap-3 flex-col`}
    >
      {!laundryByIdPage && <BreadcrumbPath paths={breadcrumbPaths} />}
      <div className="flex md:items-center max-md:flex-col gap-3">
        {!laundryByIdPage && (
          <Link
            to="addNewOrder"
            className="md:min-w-[200px] md:max-w-[200px] bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 min-h-[38.34px]"
          >
            {t("Add new order")}
          </Link>
        )}
        <CustomSelect
          value={today}
          onChange={(e) => {
            setToday(e.target.value);
            setCurrentPage(1);
          }}
          className={`md:min-w-[200px] md:max-w-[200px]`}
          children={
            <>
              <CustomOption content={t("Orders All Time")} value="0" />
              <CustomOption content={t("Orders For Today")} value="1" />
            </>
          }
        />
        <CustomSelect
          value={orderStatusId}
          onChange={(e) => {
            setOrderStatusId(e.target.value);
            setCurrentPage(1);
          }}
          className={`md:min-w-[200px] md:max-w-[200px]`}
          children={
            <>
              <CustomOption content={t("All Orders")} value="1" />
              <CustomOption content={t("Urgent")} value="2" />
              <CustomOption content={t("Indirect")} value="3" />
            </>
          }
        />
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by order number")}
          loading={searchLoading}
        />
      </div>
      <OutterTable>
        <thead>
          <tr>
            <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
            {isSuper && !laundryByIdPage && (
              <MainHeadTableItem
                content={t("Laundry")}
                className={`max-md:hidden`}
              />
            )}
            <MainHeadTableItem
              content={t("Number")}
              className={`max-md:hidden w-[100px]`}
            />
            <MainHeadTableItem
              content={t("Source")}
              className={`max-md:hidden w-[70px]`}
            />
            <MainHeadTableItem content={t("Owner")} className={`w-[75px]`} />
            <MainHeadTableItem
              content={t("Status")}
              className={`max-md:hidden w-[90px]`}
            />
            <MainHeadTableItem
              content={t("Base Cost")}
              className={`max-md:hidden w-[90px]`}
            />
            <MainHeadTableItem
              content={t("Collection Support")}
              className={`max-md:hidden w-[90px]`}
            />
            <MainHeadTableItem className={`md:hidden w-[70px]`} />
            <MainHeadTableItem
              content={t("Order Date")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={"..."}
              className={`max-md:hidden w-[70px]`}
            />
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: 10 }).map((_, outIndex) => (
              <tr key={outIndex}>
                {Array.from({ length: isSuper ? 10 : 9 }).map((_, inIndex) => (
                  <td
                    key={inIndex}
                    className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                  >
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ))
          ) : orders.length > 0 ? (
            orders.map((order) => (
              <>
                <tr key={order?.id} className="">
                  <MainBodyTableItem
                    content={order?.id}
                    className={`w-[70px]`}
                  />
                  {isSuper && !laundryByIdPage && (
                    <MainBodyTableItem
                      content={
                        lng === "Arabic"
                          ? order?.laundry.name_ar
                          : order?.laundry.name_en
                      }
                      className={`max-md:hidden`}
                    />
                  )}
                  <MainBodyTableItem
                    content={order?.order_number}
                    className={`max-md:hidden w-[100px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={order?.type_order}
                    className={`max-md:hidden w-[70px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={order?.address?.full_name || order?.user?.name}
                    className={`w-[75px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={order?.status}
                    className={`max-md:hidden w-[90px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={`${parseFloat(order?.base_cost).toFixed(2)} $`}
                    className={`max-md:hidden w-[90px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={
                      order.collection === 1 ? (
                        <FaCheck
                          className="mx-auto text-PrimaryColor"
                          size={17}
                        />
                      ) : (
                        <FaWindowClose
                          className="mx-auto text-PrimaryColor"
                          size={17}
                        />
                      )
                    }
                    className={`max-md:hidden w-[90px] overflow-x-auto`}
                  />
                  <MainBodyTableItem
                    content={
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(order?.id)}
                      >
                        {moreOpen[order?.id] ? (
                          <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                        ) : (
                          <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                        )}
                      </span>
                    }
                    className={`md:hidden w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={order?.order_date}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <div className="flex justify-center items-center gap-1">
                        <Link
                          className={`${
                            order?.order_type?.type === "Urgent"
                              ? "block"
                              : "hidden"
                          } cursor-pointer flex justify-center items-center`}
                          to={`${order?.id}/updateOrder`}
                        >
                          <AiOutlineEdit
                            className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                        <Link
                          to={`${order?.id}`}
                          className={`cursor-pointer flex justify-center items-center`}
                        >
                          <IoMdMore
                            className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                        {order?.type_order === "web" && (
                          <div
                            onClick={() => {
                              setMakeDecisionOpen(true);
                              setAction("delete");
                              setType("order");
                              setItemId(order.id);
                            }}
                            className={`cursor-pointer flex justify-center items-center`}
                          >
                            <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                          </div>
                        )}
                      </div>
                    }
                    className={`max-md:hidden w-[70px]`}
                  />
                </tr>
                <InnerTable openStatus={moreOpen[order?.id]}>
                  <tbody>
                    <tr>
                      <MainBodyTableItem
                        content={t("Id")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.id} />
                    </tr>
                    {isSuper && !laundryByIdPage && (
                      <tr>
                        <MainBodyTableItem
                          content={t("Laundry")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            lng === "Arabic"
                              ? order?.laundry.name_ar
                              : order?.laundry.name_en
                          }
                        />
                      </tr>
                    )}
                    <tr>
                      <MainBodyTableItem
                        content={t("Number")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.order_number} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Source")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.type_order} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Owner")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={order?.address?.full_name || order?.user?.name}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Status")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.status} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Base Cost")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={`${parseFloat(order?.base_cost).toFixed(2)} $`}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Collection Support")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          order.collection === 1 ? (
                            <FaCheck
                              className="mx-auto text-PrimaryColor"
                              size={17}
                            />
                          ) : (
                            <FaWindowClose
                              className="mx-auto text-PrimaryColor"
                              size={17}
                            />
                          )
                        }
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Order Date")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.order_date} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={"..."}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          <div className="flex justify-center items-center mx-auto gap-1">
                            <Link
                              className={`${
                                order?.order_type?.type === "Urgent"
                                  ? "block"
                                  : "hidden"
                              } cursor-pointer flex justify-center items-center`}
                              to={`${order?.id}/updateOrder`}
                            >
                              <AiOutlineEdit
                                size={15}
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                            <Link
                              to={`${order?.id}`}
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              <IoMdMore
                                className={`cursor-pointer hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                            {order?.type_order === "web" && (
                              <div
                                onClick={() => {
                                  setMakeDecisionOpen(true);
                                  setAction("delete");
                                  setType("order");
                                  setItemId(order.id);
                                }}
                                className={`cursor-pointer flex justify-center items-center`}
                              >
                                <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </tr>
                  </tbody>
                </InnerTable>
              </>
            ))
          ) : (
            <div className="whitespace-nowrap p-[12px] text-[13px]">
              {t("No orders available")}
            </div>
          )}
        </tbody>
      </OutterTable>

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        itemPerPage={itemPerPage}
        handlePageClick={handlePageClick}
        from={from}
        to={to}
        total={total}
      />
    </div>
  );
}

export default OrderData;
