import React, { useEffect, useState } from "react";
import { postLaundry } from "../Api/Laundry";
import { fetchLaundryItems } from "../Api/Items";
import BreakDiv from "../Components/BreakDiv";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { fetchServices } from "../Api/Services";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDashboard } from "../Context/DashboardContext";
import CustomSelect from "../Components/CustomSelect";
import CustomOption from "../Components/CustomOption";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function AddLaundry() {
  const { selectedLanguage } = useDashboard();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const [showUrgent, setShowUrgent] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordConfirm, setHidePasswordConfirm] = useState(true);
  const [showPicOptions, setShowPicOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    phoneNumber: "",
    emailLaundry: "",
    collection: "",
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    city: "",
    addressLine: "",
    lat: "",
    lng: "",
    undirectItems: [],
    urgentItems: [],
    imgs: [],
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleImageUpload = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    setData((prevData) => ({
      ...prevData,
      imgs: [...prevData.imgs, ...newFiles], // Append new images
    }));
  };

  const deleteImage = (index) => {
    setData((prevData) => ({
      ...prevData,
      imgs: prevData.imgs.filter((_, i) => i !== index),
    }));
  };
  const handleItemChange = (e, itemId, type) => {
    const isChecked = e.target.checked;
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = isChecked
        ? [...prevData[key], { laundry_item_id: itemId, services: [] }]
        : prevData[key].filter((item) => item.laundry_item_id !== itemId);

      return { ...prevData, [key]: updatedItems };
    });
  };

  const handlePriceChange = (itemId, serviceId, newPrice, type) => {
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = prevData[key].map((item) => {
        if (item.laundry_item_id === itemId) {
          const updatedServices = item.services.map((service) =>
            service.service_id === serviceId
              ? {
                  ...service,
                  price: newPrice,
                  order_type: type === 1 ? 1 : 2,
                }
              : service
          );
          return { ...item, services: updatedServices };
        }
        return item;
      });

      return { ...prevData, [key]: updatedItems };
    });
  };

  const handleServiceChange = (e, serviceId, itemId, type) => {
    const isChecked = e.target.checked;
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = prevData[key].map((item) => {
        if (item.laundry_item_id === itemId) {
          const updatedServices = isChecked
            ? item.services.some(
                (service) =>
                  service.service_id === serviceId && service.item_id === itemId
              )
              ? item.services
              : [
                  ...item.services,
                  { service_id: serviceId, item_id: itemId, price: 0 },
                ]
            : item.services.filter(
                (service) =>
                  !(
                    service.service_id === serviceId &&
                    service.item_id === itemId
                  )
              );

          return { ...item, services: updatedServices };
        }
        return item;
      });

      return { ...prevData, [key]: updatedItems };
    });
  };

  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  const getLaundryItems = async () => {
    try {
      setItemsLoading(true);
      const response = await fetchLaundryItems(0);
      console.log(response);
      setItems(response.data);
    } catch (err) {
      console.error("get laundry items err : ", err);
    } finally {
      setItemsLoading(false);
    }
  };
  const getServices = async () => {
    try {
      setServicesLoading(true);
      const response = await fetchServices(0);
      console.log(response);
      setServices(response.data);
    } catch (err) {
      console.error("get services err : ", err);
    } finally {
      setServicesLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const laundryData = {
      name_en: data.nameEn,
      name_ar: data.nameAr,
      description_en: data.descriptionEn,
      description_ar: data.descriptionAr,
      phone_number: data.phoneNumber,
      email_laundry: data.emailLaundry,
      collection: data.collection,
      city: data.city,
      address_line_1: data.addressLine,
      lat: data.lat,
      lng: data.lng,
      name: data.name,
      email: data.email,
      password: data.password,
      password_confirmation: data.passwordConfirm,
      array_ids: [...data.undirectItems, ...data.urgentItems].flatMap((item) =>
        item.services.map((service) => ({
          laundry_item_id: item.laundry_item_id,
          price: service.price,
          service_id: service.service_id,
          order_type_id: service.order_type,
        }))
      ),
    };
    const formData = new FormData();

    // Append laundryData to formData
    Object.entries(laundryData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          Object.entries(item).forEach(([subKey, subValue]) => {
            formData.append(`${key}[${index}][${subKey}]`, subValue);
          });
        });
      } else {
        formData.append(key, value);
      }
    });

    // Append image data (handle multiple images)
    data.imgs.forEach((img, index) => {
      formData.append(`array_url[${index}][url_image]`, img);
    });

    try {
      setLoading(true);
      const response = await postLaundry(formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/laundries";
      }
    } catch (err) {
      console.error("add new laundry err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLaundryItems();
    getServices();
  }, [lng]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Laundries"), url: "/dashboard/laundries" },
    { name: t("Add new laundry"), url: "/addNewLaundry" },
  ];

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="font-[500]">{t("Add new laundry")}</div>
      <div className="flex flex-col gap-3">
        <label className="text-[13px] flex items-center gap-1 cursor-pointer">
          {t("Admin Information")} <span className="text-red-600">*</span> :
        </label>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="name"
            >
              {t("Name")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.name}
              name="name"
              onChange={changeHandler}
              id="name"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="email"
            >
              {t("Email")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.email}
              name="email"
              onChange={changeHandler}
              id="email"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Email")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="password"
            >
              {t("Password")} <span className="text-red-600">*</span> :
            </label>
            <div className="w-full relative">
              <input
                type={hidePassword ? "password" : "text"}
                value={data.password}
                name="password"
                onChange={changeHandler}
                id="password"
                className={`${
                  selectedLanguage !== "Arabic"
                    ? "pr-5 pl-[12px]"
                    : "pl-5 pr-[12px]"
                } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]`}
                placeholder={t("Password")}
              />
              <div
                onClick={() => setHidePassword(!hidePassword)}
                className={`${
                  selectedLanguage === "Arabic"
                    ? "left-0 rounded-l-[0.25rem]"
                    : "right-0 rounded-r-[0.25rem]"
                } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
              >
                {hidePassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="passwordConfirm"
            >
              {t("Password Confirmation")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <div className="w-full relative">
              <input
                type={hidePasswordConfirm ? "password" : "text"}
                value={data.passwordConfirm}
                name="passwordConfirm"
                onChange={changeHandler}
                id="passwordConfirm"
                className={`${
                  selectedLanguage !== "Arabic"
                    ? "pr-5 pl-[12px]"
                    : "pl-5 pr-[12px]"
                } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]`}
                placeholder={t("Password Confirmation")}
              />
              <div
                onClick={() => setHidePasswordConfirm(!hidePasswordConfirm)}
                className={`${
                  selectedLanguage === "Arabic"
                    ? "left-0 rounded-l-[0.25rem]"
                    : "right-0 rounded-r-[0.25rem]"
                } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
              >
                {hidePasswordConfirm ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
          </div>
        </div>
        <BreakDiv />
        <label className="text-[13px] flex items-center gap-1 cursor-pointer">
          {t("Laundry Information")} <span className="text-red-600">*</span> :
        </label>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEn"
            >
              {t("Name In English")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameEn}
              name="nameEn"
              onChange={changeHandler}
              id="nameEN"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameAr"
            >
              {t("Name In Arabic")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameAr}
              name="nameAr"
              onChange={changeHandler}
              id="nameAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionEn"
            >
              {t("Description In English")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <textarea
              value={data.descriptionEn}
              name="descriptionEn"
              onChange={changeHandler}
              id="descriptionEn"
              className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
              placeholder={t("Description In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionAr"
            >
              {t("Description In Arabic")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <textarea
              value={data.descriptionAr}
              name="descriptionAr"
              onChange={changeHandler}
              id="descriptionAr"
              className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
              placeholder={t("Description In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="phoneNumber"
            >
              {t("Phone Number")} <span className="text-red-600">*</span> :
            </label>
            <input
              type="text"
              pattern="[0-9]+"
              value={data.phoneNumber}
              name="phoneNumber"
              onChange={changeHandler}
              id="phoneNumber"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("ex: 0963 654 656 65")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="emailLaundry"
            >
              {t("Laundry Email")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.emailLaundry}
              name="emailLaundry"
              onChange={changeHandler}
              id="emailLaundry"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Laundry Email")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="collection"
            >
              {t("Collection Items Support")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <CustomSelect
              className="w-full"
              id="collection"
              name={`collection`}
              onChange={changeHandler}
              value={data.collection}
              children={
                <>
                  <CustomOption content={t("Select")} value="" hide={true} />
                  <CustomOption content={t("Support")} value="1" />
                  <CustomOption content={t("Does not support")} value="0" />
                </>
              }
            />
          </div>
        </div>
        <label className="text-[13px] flex items-center gap-1">
          {`${t("Undirect Orders")}`}
          <span className="text-red-600">*</span> :
        </label>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="items"
          >
            {t("Select Items")} <span className="text-red-600">*</span> :
          </label>
          {itemsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div id="items" className="flex flex-col gap-2 text-[13px] w-full">
              {items.map((item) => {
                const selectedItem = data.undirectItems.find(
                  (selectedItem) => selectedItem.laundry_item_id === item.id
                );
                const isChecked = Boolean(selectedItem);

                return (
                  <div
                    className="text-MainText bg-white flex flex-col gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`item-${item.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) => handleItemChange(e, item.id, 2)}
                      />
                      <label htmlFor={`item-${item.id}`}>
                        {selectedLanguage === "Arabic"
                          ? item.item_type_ar
                          : item.item_type_en}
                      </label>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className="text-[13px] flex items-center gap-1 cursor-pointer"
                        htmlFor={`services${item.id}`}
                      >
                        {t("Select Services")}
                        <span className="text-red-600">*</span> :
                      </label>
                      {servicesLoading ? (
                        <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
                      ) : (
                        <div
                          id={`services${item.id}`}
                          className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
                        >
                          {services.map((service) => {
                            const isServiceSelected =
                              selectedItem?.services.some(
                                (s) => s.service_id === service.id
                              );
                            const price =
                              selectedItem?.services.find(
                                (s) => s.service_id === service.id
                              )?.price || "";
                            return (
                              <div
                                className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                                key={service.id}
                              >
                                <div className="flex items-center gap-2">
                                  <input
                                    id={`service-${service.id}`}
                                    type="checkbox"
                                    className="focus:ring-0"
                                    checked={isServiceSelected}
                                    disabled={!isChecked}
                                    onChange={(e) =>
                                      handleServiceChange(
                                        e,
                                        service.id,
                                        item.id,
                                        2
                                      )
                                    }
                                  />
                                  <label htmlFor={`service-${service.id}`}>
                                    {selectedLanguage === "Arabic"
                                      ? service.name_ar
                                      : service.name_en}
                                  </label>
                                </div>
                                <div className="flex items-center gap-2">
                                  <label htmlFor={`price-${service.id}`}>
                                    {t("Price")}
                                  </label>
                                  <input
                                    className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none"
                                    id={`price-${service.id}`}
                                    type="number"
                                    value={price}
                                    disabled={!isServiceSelected} // Disable if the item is not checked
                                    onChange={(e) =>
                                      handlePriceChange(
                                        item.id,
                                        service.id,
                                        parseFloat(e.target.value),
                                        2
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          onClick={() => setShowUrgent(!showUrgent)}
          className="md:w-fit bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 min-h-[38.34px]"
        >
          {showUrgent
            ? t("I do not need to add urgent orders")
            : t("Add urgent orders")}
        </div>
        <div
          className={`${
            showUrgent ? "block" : "hidden"
          } animate-flashing flex flex-col gap-3`}
        >
          <label className="text-[13px] flex items-center gap-1">
            {`${t("Urgent Orders")}`}
            <span className="text-red-600">*</span> :
          </label>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="items"
            >
              {t("Select Items")} <span className="text-red-600">*</span> :
            </label>
            {itemsLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <div
                id="items"
                className="flex flex-col gap-2 text-[13px] w-full"
              >
                {items.map((item) => {
                  const selectedItem = data.urgentItems.find(
                    (selectedItem) => selectedItem.laundry_item_id === item.id
                  );
                  const isChecked = Boolean(selectedItem);

                  return (
                    <div
                      className="text-MainText bg-white flex flex-col gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                      key={item.id}
                    >
                      <div className="flex items-center gap-2">
                        <input
                          id={`item-${item.id}`}
                          type="checkbox"
                          className="focus:ring-0"
                          checked={isChecked}
                          onChange={(e) => handleItemChange(e, item.id, 1)}
                        />
                        <label htmlFor={`item-${item.id}`}>
                          {selectedLanguage === "Arabic"
                            ? item.item_type_ar
                            : item.item_type_en}
                        </label>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          className="text-[13px] flex items-center gap-1 cursor-pointer"
                          htmlFor={`services${item.id}`}
                        >
                          {t("Select Services")}
                          <span className="text-red-600">*</span> :
                        </label>
                        {servicesLoading ? (
                          <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
                        ) : (
                          <div
                            id={`services${item.id}`}
                            className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
                          >
                            {services.map((service) => {
                              const isServiceSelected =
                                selectedItem?.services.some(
                                  (s) => s.service_id === service.id
                                );
                              const price =
                                selectedItem?.services.find(
                                  (s) => s.service_id === service.id
                                )?.price || "";
                              return (
                                <div
                                  className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                                  key={service.id}
                                >
                                  <div className="flex items-center gap-2">
                                    <input
                                      id={`service-${service.id}`}
                                      type="checkbox"
                                      className="focus:ring-0"
                                      checked={isServiceSelected}
                                      disabled={!isChecked}
                                      onChange={(e) =>
                                        handleServiceChange(
                                          e,
                                          service.id,
                                          item.id,
                                          1
                                        )
                                      }
                                    />
                                    <label htmlFor={`service-${service.id}`}>
                                      {selectedLanguage === "Arabic"
                                        ? service.name_ar
                                        : service.name_en}
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <label htmlFor={`price-${service.id}`}>
                                      {t("Price")}
                                    </label>
                                    <input
                                      className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none"
                                      id={`price-${service.id}`}
                                      type="number"
                                      value={price}
                                      disabled={!isServiceSelected} // Disable if the item is not checked
                                      onChange={(e) =>
                                        handlePriceChange(
                                          item.id,
                                          service.id,
                                          parseFloat(e.target.value),
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <label className="text-[13px] flex items-center gap-1">
            {`${t("Address")}`}
            <span className="text-red-600">*</span> :
          </label>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`city`}
              >
                {t("City")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={data.city}
                onChange={changeHandler}
                name="city"
                id={`city`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("City")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`addressLine`}
              >
                {t("Address Line")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={data.addressLine}
                onChange={changeHandler}
                name="addressLine"
                id={`addressLine`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address Line")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`lat`}
              >
                {t("Lat")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={data.lat}
                onChange={changeHandler}
                name="lat"
                id={`lat`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lat")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`lng`}
              >
                {t("Lng")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={data.lng}
                onChange={changeHandler}
                name="lng"
                id={`lng`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lng")}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="img"
          >
            {t("Images")} <span className="text-red-600">*</span> :
          </label>
          <input
            multiple
            onChange={handleImageUpload}
            type="file"
            id="img"
            className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            placeholder={t("Images")}
          />
        </div>
        {data.imgs.length > 0 && (
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white w-fit">
            {data.imgs.map((file, index) => (
              <div
                onMouseOver={() =>
                  setShowPicOptions((prevState) => ({
                    ...prevState,
                    [index]: true,
                  }))
                }
                onMouseLeave={() =>
                  setShowPicOptions((prevState) => ({
                    ...prevState,
                    [index]: false,
                  }))
                }
                key={index}
                className="w-full relative max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[200px]"
              >
                <div
                  className={`${
                    showPicOptions[index] ? "block" : "hidden"
                  } animate-flashing absolute left-0 top-0 w-full h-full bg-black/25 max-md:bg-transparent`}
                >
                  <div
                    onClick={() => deleteImage(index)}
                    className="mt-2 mx-2 w-fit cursor-pointer text-MainText flex justify-center items-center rounded-full p-2 bg-[#d8d7d7] hover:bg-[#5f5f5f] hover:text-white duration-300"
                  >
                    <AiOutlineClose size={15} />
                  </div>
                </div>
                <img
                  className="w-full h-full object-cover"
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt={`image ${index}`}
                />
              </div>
            ))}
          </div>
        )}
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Add")
          )}
        </button>
      </div>
    </form>
  );
}

export default AddLaundry;
