import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { usePopup } from "../Context/PopupContext";
import { useDashboard } from "../Context/DashboardContext";
import Pagination from "../Components/Pagination";
import { fetchDrivers } from "../Api/Drivers";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import BreadcrumbPath from "./BreadcrumbPath";

function DriversData({ laundryByIdPage }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const { setType, setAction, setMakeDecisionOpen, setItemId } = usePopup();
  const { updateDrivers } = useDashboard();

  const search = async () => {
    getDriversData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getDriversData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchDrivers(
        currentPage,
        query,
        laundryByIdPage && id
      );
      console.log(response);
      setDrivers(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get drivers data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getDriversData();
  }, [updateDrivers, currentPage]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Drivers"), url: "/drivers" },
  ];
  return (
    <div
      className={`${
        !laundryByIdPage && "max-md:min-h-screen"
      } flex gap-3 flex-col`}
    >
      {!laundryByIdPage && <BreadcrumbPath paths={breadcrumbPaths} />}
      <div className="flex md:items-center max-md:flex-col gap-3">
        {!laundryByIdPage && (
          <Link
            to={`addNewDriver`}
            className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[113px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
          >
            {t("Add new driver")}
          </Link>
        )}
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by driver name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              {isSuper && !laundryByIdPage && (
                <MainHeadTableItem content={t("Laundry id")} />
              )}
              <MainHeadTableItem content={t("Name")} />
              <MainHeadTableItem
                content={t("Email")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Driver id")}
                className={`max-md:hidden w-[180px]`}
              />
              <MainHeadTableItem
                content={t("...")}
                className={`max-md:hidden w-[60px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 6 : 5 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : drivers.length > 0 ? (
              drivers.map((driver) => (
                <>
                  <tr key={driver.user_id} className="">
                    <MainBodyTableItem
                      content={driver.user_id}
                      className={`w-[70px]`}
                    />
                    {isSuper && !laundryByIdPage && (
                      <MainBodyTableItem content={driver.laundry_id} />
                    )}
                    <MainBodyTableItem content={driver?.user?.name} />
                    <MainBodyTableItem
                      content={driver?.user?.email}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={driver?.user?.driver_id}
                      className={`max-md:hidden w-[180px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(driver.user_id)}
                        >
                          {moreOpen[driver.user_id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <span className="flex items-center mx-auto">
                          <Link
                            to={`${driver.user_id}`}
                            className="flex justify-center items-center"
                          >
                            <AiOutlineEdit
                              size={15}
                              className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                          <AiFillDelete
                            onClick={() => {
                              setAction("delete");
                              setType("driver");
                              setMakeDecisionOpen(true);
                              setItemId(driver.user_id);
                            }}
                            size={15}
                            className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                          />
                        </span>
                      }
                      className={`max-md:hidden w-[60px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[driver.user_id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver.id} />
                      </tr>
                      {isSuper && !laundryByIdPage && (
                        <tr>
                          <MainBodyTableItem
                            content={t("Laundry Id")}
                            className={`font-bold`}
                          />
                          <MainBodyTableItem content={driver.laundry_id} />
                        </tr>
                      )}
                      <tr>
                        <MainBodyTableItem
                          content={t("Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.name} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Email")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.email} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Driver id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.driver_id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={"..."}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <div className="mx-auto flex items-center gap-2 w-fit h-fit">
                              <Link
                                to={`${driver.user_id}`}
                                className="flex justify-center items-center"
                              >
                                <AiOutlineEdit
                                  size={15}
                                  className=" cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                                />
                              </Link>
                              <AiFillDelete
                                onClick={() => {
                                  setAction("delete");
                                  setType("driver");
                                  setMakeDecisionOpen(true);
                                  setItemId(driver.user_id);
                                }}
                                size={15}
                                className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                              />
                            </div>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No drivers available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default DriversData;
