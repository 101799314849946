import React, { useEffect, useState } from "react";
import { useDashboard } from "../Context/DashboardContext";
import {
  fetchAdvertisementById,
  updateAdvertisement,
  updateAdvertisementMedia,
} from "../Api/Advertisments";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { isSuper } from "../Api/api";
import { useParams } from "react-router-dom";
import { usePopup } from "../Context/PopupContext";
import MakeDecision from "../Components/MakeDecision";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import CustomSelect from "../Components/CustomSelect";
import CustomOption from "../Components/CustomOption";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function UpdateAdvertisement() {
  const { t } = useTranslation();

  const {
    laundries,
    getLaundriesData,
    updateAdPics,
    setUpdateAdPics,
    selectedLanguage,
  } = useDashboard();
  const { setAction, setType, setItemId, setMakeDecisionOpen } = usePopup();
  const laundryId = localStorage.getItem("laundryId");
  const [selectedLaundryId, setSelectedLaundryId] = useState(laundryId || "");
  const [loading, setLoading] = useState(false);
  const [showPicOptions, setShowPicOptions] = useState({});
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    points: "",
    startDate: "",
    endDate: "",
    imgs: [],
  });
  const { id } = useParams();
  const getAdvertisementsData = async () => {
    try {
      const response = await fetchAdvertisementById(id);
      console.log(response);
      setData({
        nameEn: response.data.name_en,
        nameAr: response.data.name_ar,
        descriptionEn: response.data.description_en,
        descriptionAr: response.data.description_ar,
        points: response.data.points,
        startDate: response.data.start_date,
        endDate: response.data.end_date,
        imgs: response.data.media.map((item) => ({
          id: item.id,
          url: item.url_image,
        })),
      });
    } catch (err) {
      console.error("get ad error : ", err);
    }
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleImageUpload = async (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    const formData = new FormData();
    newFiles.forEach((file, index) => {
      formData.append(`url_media[${index}]`, file);
    });
    try {
      const response = await updateAdvertisementMedia(id, formData);
      console.log(response);
      if (response.success === true) {
        toastSuccess(response.message);
        setUpdateAdPics((prev) => prev + 1);
      }
    } catch (err) {
      console.error("update ad pics err : ", err);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name_en", data.nameEn);
    formData.append("name_ar", data.nameAr);
    formData.append("description_en", data.descriptionEn);
    formData.append("description_ar", data.descriptionAr);
    formData.append("points", data.points);
    formData.append("start_date", data.startDate);
    formData.append("end_date", data.endDate);
    try {
      setLoading(true);
      const response = await updateAdvertisement(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/advertisements";
      }
    } catch (err) {
      console.error("add new advertisements err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLaundriesData();
  }, []);
  useEffect(() => {
    getAdvertisementsData(0);
  }, [updateAdPics]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Advertisements"), url: "/dashboard/advertisements" },
    {
      name: `${t("Update advertisement")} ${id}`,
      url: `${id}/updateAdvertisement`,
    },
  ];

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="font-[500]">{t("Update advertisement")}</div>
      <div className="flex flex-col gap-3">
        {isSuper && (
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEn"
            >
              {t("Choose A Laundry")} <span className="text-red-600">*</span> :
            </label>
            <CustomSelect
              value={selectedLaundryId}
              onChange={(e) => {
                setSelectedLaundryId(e.target.value);
              }}
              className={`w-full`}
              children={
                <>
                  <CustomOption
                    content={t("Choose A Laundry")}
                    value=""
                    hide={true}
                  />
                  {laundries.map((laundry) => (
                    <CustomOption
                      content={
                        selectedLanguage === "Arabic"
                          ? laundry?.laundry?.name_ar
                          : laundry?.laundry?.name_en
                      }
                      value={laundry?.laundry?.id}
                      key={laundry?.laundry?.id}
                    />
                  ))}
                </>
              }
            />
          </div>
        )}
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEN"
            >
              {t("Name In English")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameEn}
              name="nameEn"
              onChange={changeHandler}
              id="nameEN"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameAr"
            >
              {t("Name In Arabic")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameAr}
              name="nameAr"
              onChange={changeHandler}
              id="nameAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionEn"
            >
              {t("Description In English")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.descriptionEn}
              name="descriptionEn"
              onChange={changeHandler}
              id="descriptionEn"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Description In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionAr"
            >
              {t("Description In Arabic")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.descriptionAr}
              name="descriptionAr"
              onChange={changeHandler}
              id="descriptionAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Description In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="startDate"
            >
              {t("Start Date")}
              <span className="text-red-600">*</span> :
            </label>
            <input
              type="date"
              value={data.startDate}
              name="startDate"
              onChange={changeHandler}
              id="startDate"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer"
              placeholder={t("Start Date")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="endDate"
            >
              {t("End Date")}
              <span className="text-red-600">*</span> :
            </label>
            <input
              type="date"
              value={data.endDate}
              name="endDate"
              onChange={changeHandler}
              id="endDate"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer"
              placeholder={t("End Date")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="points"
            >
              {t("Points")}
              <span className="text-red-600">*</span> :
            </label>
            <input
              type="number"
              value={data.points}
              name="points"
              onChange={changeHandler}
              id="points"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer"
              placeholder={t("Points")}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="img"
          >
            {t("Images")} <span className="text-red-600">*</span> :
          </label>
          <input
            multiple
            onChange={handleImageUpload}
            type="file"
            id="img"
            className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            placeholder={t("Images")}
          />
        </div>
        {data.imgs.length > 0 && (
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white w-fit">
            {data.imgs.length > 0 &&
              data.imgs.map((file, index) => (
                <div
                  onMouseOver={() =>
                    setShowPicOptions((prevState) => ({
                      ...prevState,
                      [index]: true,
                    }))
                  }
                  onMouseLeave={() =>
                    setShowPicOptions((prevState) => ({
                      ...prevState,
                      [index]: false,
                    }))
                  }
                  className="w-full relative max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[100px]"
                >
                  <div
                    className={`${
                      showPicOptions[index] ? "md:block" : "md:hidden"
                    } animate-flashing absolute left-0 top-0 w-full h-full bg-black/25 max-md:bg-transparent`}
                  >
                    <div
                      onClick={() => {
                        setType("ad");
                        setAction("delete");
                        setItemId(file.id);
                        setMakeDecisionOpen(true);
                      }}
                      className="mt-2 mx-2 w-fit cursor-pointer text-MainText flex justify-center items-center rounded-full p-2 bg-[#d8d7d7] hover:bg-[#5f5f5f] hover:text-white duration-300"
                    >
                      <AiOutlineClose size={15} />
                    </div>
                  </div>
                  <img
                    className="w-full h-full object-cover"
                    key={index}
                    src={file.url}
                    alt={`image ${index}`}
                  />
                </div>
              ))}
          </div>
        )}
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Update")
          )}
        </button>
      </div>
      <MakeDecision />
    </form>
  );
}

export default UpdateAdvertisement;
