import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      // SelectLanguage
      "Choose Language": "Choose Language",
      English: "English",
      Arabic: "Arabic",
      // Admin
      "Welcome": "Welcome",
      "Please login": "Please login",
      "Email": "Email",
      "Password":"Password",
      "Your Email": "Your Email",
      "Your Password": "Your Password",
      "Login": "Login",
      // MainDashPage 
      "Orders for today": "Orders for today",
      "Total orders": "Total orders",
      "Pending orders": "Pending orders",
      "Cars in service": "Cars in service",
      "Income this month": "Income this month",
      "Orders Chart": "Orders Chart",
      "Daily": "Daily",
      "Monthly": "Monthly",
      "Yearly": "Yearly",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "Update": "Update",
      // Dashboard
      "Super Admin": "Super Admin",
      // Laundries 
      "Add new laundry": "Add new laundry",
      "Id": "Id",
      "Name": "Name",
      "Description": "Description",
              "Phone Number": "Phone Number",
                  "Urgent Orders": "Urgent Orders",
              "Collection Support":"Collection Support",
              "Support": "Support",
      "Does not support":"Does not support",
      "Address": "Address",
      "I do not need to add address":"I do not need to add address",
      "Add address":"Add address",
      "Admin id": "Admin id",
      "Status": "Status",
      "Image": "Image",
      "Active": "Active",
      "Not active": "Not active",
      "More": "More",
      "No laundries available": "No laundries available",
      // LaundryById 
      "Edit this laundry": "Edit this laundry",
      "Lat,Lng": "Lat,Lng",
      "Point": "Point",
      "Laundry Items": "Laundry Items",
      "Price": "Price",
      "Laundry Services": "Laundry Services",
      "Service Name": "Service Name",
      "Laundry Images": "Laundry Images",
      "Laundry Orders": "Laundry Orders",
      "Laundry Name": "Laundry Name",
      "Order Number": "Order Number",
      "Order Source": "Order Source",
      "Order Owner": "Order Owner",
      "Status": "Status",
      "Base Cost": "Base Cost",
      "Total Price": "Total Price",
      "Note": "Note",
      "Order Date": "Order Date",
      "Pickup Time": "Pickup Time",
      "Delivery Time": "Delivery Time",
      "No orders available": "No orders available",
      "Laundry Drivers": "Laundry Drivers",
      "Driver id": "Driver id",
      "No drivers available": "No drivers available",
      "Laundry Cars": "Laundry Cars",
      "Driver Name": "Driver Name",
      "Lat":"Lat",
      "Lng": "Lng",
              "No cars available": "No cars available",
      "Laundry Addresses": "Laundry Addresses",
      "Laundry Items With Services":"Laundry Items With Services",
      // AddLaundry
      "Name In English": "Name In English",
      "Name In Arabic": "Name In Arabic",
      "Description In English": "Description In English",
      "Description In Arabic": "Description In Arabic",
      "Phone Number": "Phone Number",
      "City": "City",
      "Point": "Point",
      "Select Admin": "Select Admin",
      "Select Items": "Select Items",
      "Select Services": "Select Services",
      "Lat": "Lat",
      "Lng": "Lng",
      "Images": "Images",
              "Add": "Add",
            "Admin Information":"Admin Information",
      "Laundry Information":"Laundry Information",
                  "Laundry Email": "Laundry Email",
                  "Collection Items Support": "Collection Items Support",
                  "Select": "Select",
                  "Support": "Support",
      "Does not support":"Does not support",
      "Undirect Orders":"Undirect Orders",
      "Address Line":"Address Line",
      "I do not need to add urgent orders":"I do not need to add urgent orders",
      "Add urgent orders":"Add urgent orders",
      // UpdateLaundry 
      "Update laundry": "Update laundry",
      "Update":"Update",
      // EmployeesAndCars
      "Car Id": "Car Id",
      "Driver Name": "Driver Name",
      "Details": "Details",
      "More": "More",
      "No data available": "No data available",
      // DriverById
      "Driver id": "Driver id",
      "Driver name": "Driver name",
      "Driver email": "Driver email",
      "Driver phone": "Driver phone",
      "Car location":"Car location",
      // Orders
      "Orders All Time": "Orders All Time",
      "Orders For Today": "Orders For Today",
      "All Orders": "All Orders",
      "Indirect": "Indirect",
      "Urgent": "Urgent",
      "Id": "Id",
      "Status": "Status",
      "Base Cost": "Base Cost",
      "Total Price": "Total Price",
      "Note": "Note",
      "Order Date": "Order Date",
      "Pickup Time": "Pickup Time",
      "Delivery Time": "Delivery Time",
      "Less": "Less",
      "More": "More",
      "No orders available": "No orders available",
      // OrdersStatsChart
      "Orders":"Orders",
      "Order":"Order",
      // UpdateDeliveryTime
      "Update Order": "Update Order",
      "Choose Date":"Choose Date",
      "Choose Time": "Choose Time",
      "Select Car": "Select Car",
      "Select Status": "Select Status",
      "pending": "pending",
      "confirmed": "confirmed",
      "request":"request",
      "cancelled": "cancelled",
      "Update": "Update",
                  // ProductItem
      "Edit":"Edit",
      // UpdateProductPrice
      "Update product price": "Update product price",
      "Product Price": "Product Price",
      "Update":"Update",
      // DashboardSidebar
"Dobe Pro": "Dobe Pro",
      "Dashboard": "Dashboard",
      "Home": "Home",
      "Laundries": "Laundries",
      "Admins": "Admins",
      "Drivers": "Drivers",
  "Cars":"Cars",
  "Car":"Car",
  "Employees And Cars": "Employees And Cars",
  "Orders": "Orders",
  "Transactions": "Transactions",
  "Prices": "Prices",
  "Reports": "Reports",
  "Language": "Language",
      "Logout": "Logout",
      "Hide": "Hide",
      "Customers":"Customers",
      "Customer":"Customer",
      "Products": "Products",
      "Notifications":"Notifications",
              "Advertisements": "Advertisements",
      "Items": "Items",
                  "Services": "Services",
      "Reports":"Reports",
      // MakeDecision
      "delete": "delete",
      "driver": "driver",
      "order":"order",
      "photo":"photo",
      "laundry item":"laundry item",
      "service":"service",
      "advertisment":"advertisment",
      "Are you sure you want to": "Are you sure you want to",
      "logout":"logout",
      "this": "this",
      "Cancel": "Cancel",
      "Ok": "Ok",
      "?": "?",
      // Pagination 
      "Prev": "Prev",
      "Next": "Next",
      "Showing": "Showing",
      "to": "to",
      "of": "of",
      "items":"items",
      // NotificationsPopup 
      "See all": "See all",
      // Users 
      "Code":"Code",
      "Email Verified At":"Email Verified At",
      "Not yet":"Not yet",
      "Image":"Image",
      "Device Token":"Device Token",
      "Points Wallet": "Points Wallet",
      "Search by customer name": "Search by customer name",
      // DriversData
      "Add new driver":"Add new driver",
      "Search by driver name":"Search by driver name",
      "Laundry id":"Laundry id",
      "No drivers available": "No drivers available",
      // AddNewDriver
      "Add New Driver":"Add New Driver",
      "Password Confirmation": "Password Confirmation",
      // UpdateDriver 
      "Update Driver":"Update Driver",
      "Image": "Image",
      // CarsData
      "Add new car":"Add new car",
      "Search by car number":"Search by car number",
      "Car number": "Car number",
            // AddNewCar 
      "Add New Car":"Add New Car",
      "Laundry":"Laundry",
      "Select laundry":"Select laundry",
      "Driver":"Driver",
      "Select driver":"Select driver",
      // UpdateCar
      "Update Car": "Update Car",
      // OrderData 
      "Add new order":"Add new order",
      "Search by order number":"Search by order number",
      "Number": "Number",
      "Source": "Source",
      "Owner":"Owner",
      // OrderDetails
      "Order Details":"Order Details",
      "Number":"Number",
      "Source":"Source",
      "Type":"Type",
      "Item":"Item",
      "Image":"Image",
      "Quantity":"Quantity",
      "Sub Total Price":"Sub Total Price",
      "Service Id": "Service Id",
      // ProductsData
      "English Name":"English Name",
      "Arabic Name":"Arabic Name",
      "Image":"Image",
      "Details":"Details",
      "Urgent Price":"Urgent Price",
      "Indirect Price":"Indirect Price",
      // Notifications
      "Type":"Type",
      "Title":"Title",
      "Body":"Body",
      "Order Id":"Order Id",
      "Read at":"Read at",
      "No notifications available":"No notifications available",
      // Advertisements
      "Add new advertisement":"Add new advertisement",
      "All Status":"All Status",
      "All Laundries":"All Laundries",
      "Search by advretisement name":"Search by advretisement name",
      "Laundry Name":"Laundry Name",
      "Points":"Points",
      // AddNewAdvertisement
      "Add new advertisement":"Add new advertisement",
      "Choose A Laundry": "Choose A Laundry",
            // UpdateAdvertisement
              "Update advertisement": "Update advertisement",
      // Admins 
              "Search by admin name": "Search by admin name",
      // UserById 
      "Customer Addresses":"Customer Addresses",
      "Country":"Country",
      "City":"City",
                  "Postal Code": "Postal Code",
                  "Full Name": "Full Name",
      "Contact Number" : "Contact Number",
      "Address Line 1":"Address Line 1",
      "Address Line 2":"Address Line 2",
              "Customer Orders": "Customer Orders",
              // AddNewItem 
              "Add new item":"Add new item",
              "Add New Item": "Add New Item",
              //   UpdateItem 
              "Update Item": "Update Item",
              //   Services 
            "Add new service":"Add new service",
            "Add New Service":"Add New Service",
            // UpdateService
                  "Update Service": "Update Service",
            //     Reports
                  "Start Date": "Start Date",
                  "End Date": "End Date",
        "Download":"Download",
    }
  },
  Arabic: {
    translation: {
      // SelectLanguage
      "Choose Language": "اختر اللغة",
      English: "الإنكليزية",
      Arabic: "العربية",
      // Admin
            "Welcome": "أهلا بكم",
      "Please login": "الرجاء تسجيل الدخول",
      "Email": "البريد الإلكتروني",
      "Password":"كلمة المرور",
      "Your Email": "البريد الإلكتروني",
      "Your Password": "كلمة المرور",
      "Login": "تسجيل دخول",
      // MainDashPage 
      "Orders for today": "الطلبات لهذا اليوم",
      "Total orders": "كل الطلبات",
      "Pending orders": "الطلبات المعلقة",
      "Cars in service": "سيارات في الخدمة",
      "Income this month": "الدخل هذا الشهر",
      "Orders Chart": "مخطط الطلبات",
      "Daily": "يومي",
      "Monthly": "شهري",
      "Yearly": "سنوي",
      "Start Date": "تاريخ البدء",
      "End Date": "تاريخ الانتهاء",
      "Update": "تحديث",
      // Dashboard
      "Super Admin": "السوبر ادمن",
            // Laundries 
      "Add new laundry": "إضافة مغسلة جديدة",
      "Id": "الرقم التسلسلي",
      "Name": "الاسم",
      "Description": "الوصف",
              "Phone Number": "رقم الهاتف",
                  "Urgent Orders": "الطلبات المياشرة",
              "Collection Support":"الطلبات المتعددة",
      "Support": "تدعم",
      "Does not support":"لا تدعم",
                  "Address": "العنوان",
           "I do not need to add address":"لا أريد إضافة عنوان",
      "Add address":"أضف عنوان",
      "Admin id": "الرقم التسلسلي للمسؤول",
      "Status": "الحالة",
      "Image": "الصورة",
      "Active": "فعال",
      "Not active": "غير فعال",
      "More": "المزيد",
      "No laundries available": "لا يوجد مغاسل متاحة",
            // LaundryById 
      "Edit this laundry": "تعديل المغسلة",
      "Lat,Lng": "خطوط العرض و الطول",
      "Point": "النقاط",
      "Laundry Items": "منتجات المغسلة",
      "Price": "السعر",
      "Laundry Services": "خدمات المغسلة",
      "Service Name": "اسم الخدمة",
      "Laundry Images": "صور المغسلة",
      "Laundry Orders": "طلبات المغسلة",
      "Laundry Name": "اسم المغسلة",
      "Order Number": "رقم الطلب",
      "Order Source": "مصدر الطلب",
      "Order Owner": "صاحب الطلب",
      "Status": "الحالة",
      "Base Cost": "التكلفة الأساسية",
      "Total Price": "السعر الإجمالي",
      "Note": "ملاحظة",
      "Order Date": "تاريخ الطلب",
      "Pickup Time": "وقت الاستلام",
      "Delivery Time": "موعد التسليم",
      "No orders available": "لا توجد طلبات متاحة",
      "Laundry Drivers": "سائقين المغسلة",
      "Driver id": "الرقم التسلسلي للسائق",
      "No drivers available": "لا يوجد سائقين متاحين",
      "Laundry Cars": "سيارات المغسلة",
      "Driver Name": "اسم السائق",
      "Lat":"خطوط العرض",
      "Lng": "خطوط الطول",
              "No cars available": "لا يوجد سيارات متاحة",
              "Laundry Addresses": "عنوان المغسلة",
      "Laundry Items With Services":"المنتجات و الخدمات",
              // AddLaundry
      "Name In English": "الاسم بالإنجليزي",
      "Name In Arabic": "الاسم بالعربي",
      "Description In English": "الوصف بالإنجليزي",
      "Description In Arabic": "الوصف بالعربي",
      "Phone Number": "رقم الهاتف",
      "City": "المدينة",
      "Point": "النقاط",
      "Select Admin": "اختر مسؤول",
      "Select Items": "اختر منتجات",
      "Select Services": "اختر خدمات",
      "Lat": "خطوط العرض",
      "Lng": "خطوط الطول",
      "Images": "الصور",
              "Add": "إضافة",
      "Admin Information":"معلومات المسؤول",
      "Laundry Information":"معلومات المغسلة",
                  "Laundry Email": "البريد الإلكتروني للمغسلة",
      "Collection Items Support": "دعم مجموعة منتجات",
                  "Select": "اختر",
                  "Support": "تدعم",
      "Does not support":"لا تدعم",
      "Undirect Orders":"الطلبات غير المباشرة",
                  "Address Line": "العنوان",
      "I do not need to add urgent orders":"لا أريد إضافة طلبات مباشرة",
      "Add urgent orders":"أضف طلبات مباشرة",
            // UpdateLaundry 
      "Update laundry": "تعديل المغسلة",
      "Update":"تحديث",
      // EmployeesAndCars
      "Car Id": "الرقم التسلسلي للسيارة",
      "Driver Name": "اسم الساثق",
      "Details": "التفاصيل",
      "More": "المزيد",
      "No data available": "لا يوجد بيانات متاحة",
      // DriverById
      "Driver id": "الرقم التسلسلي للساثق",
      "Driver name": "اسم الساثق",
      "Driver email": "ايميل السائق",
      "Driver phone": "رقم السائق",
      "Car location":"موقع السيارة",
      // Orders
      "Orders All Time": "كل الأوقات",
      "Orders For Today": "اليوم",
      "All Orders": "كل الطلبات",
      "Indirect": "الغير مباشرة",
      "Urgent": "المباشرة",
      "Id": "الرقم التسلسلي",
      "Status": "الحالة",
      "Base Cost": "التكلفة الأساسية",
      "Total Price": "السعر الكلي",
      "Note": "ملاحظة",
      "Order Date": "تاريخ الطلب",
      "Pickup Time": "وقت الاستلام",
      "Delivery Time": "وقت التسليم",
      "Less": "أقل",
      "More": "المزيد",
      "No orders available": "لا يوجد بيانات متاحة",
      // OrdersStatsChart
      "Orders":"الطلبات",
      "Order":"الطلب",
      // UpdateDeliveryTime
      "Update Order": "تعديل الطلب",
      "Choose Date":"اختر التاريخ",
      "Choose Time": "اختر الوقت",
      "Select Car": "اختر السيارة",
      "Select Status": "اختر الحالة",
      "pending": "معلق",
      "confirmed": "مؤكد",
      "request":"غير مؤكد",
      "cancelled": "ملغي",
      "Update": "تحديث",
      // ProductItem
      "Edit":"تعديل",
      // UpdateProductPrice
      "Update product price": "تعديل السعر",
      "Product Price": "سعر المنتج",
"Update": "تحديث",     
 // DashboardSidebar
"Dobe Pro": "دوبي برو",
      "Dashboard": "الرئيسية",
      "Home": "الرئيسية",
  "Laundries": "المغاسل",
      "Admins": "المسؤولين",
      "Drivers": "السائقين",
  "Cars":"السيارات",
  "Car":"السيارة",
  "Employees And Cars": "السائقين و السيارات",
  "Orders": "الطلبات",
  "Transactions": "Transactions",
  "Prices": "الأسعار",
  "Reports": "التقارير",
  "Language": "اللغة",
      "Logout": "تسجيل خروج",
      "Hide": "غلق النافذة",
            "Customers":"العملاء",
            "Customer":"العميل",
      "Products": "المنتجات",
      "Notifications":"الإشعارات",
              "Advertisements": "الإعلانات",
              "Items": "المنتجات",
                  "Services": "الخدمات",
      "Reports":"التقارير",
      // MakeDecision
      "delete": "تحذف",
      "driver": "الساثق",
      "order":"الطلب",
      "photo":"الصورة",
      "laundry item":"العنصر",
      "service":"الخدمة",
      "advertisment":"الإعلان",
      "Are you sure you want to": "هل انت متأكد أنك تريد",
      "logout": "تسجيل الخروج",
      "this": "هذا",
      "Cancel": "إلغاء",
      "Ok": "تأكيد",
      "?": "؟",
      // Pagination 
      "Prev": "السابق",
      "Next": "التالي",
      "Showing": "عرض",
      "to": "إلى",
      "of": "من أصل",
      "items": "عناصر",
      // NotificationsPopup 
      "See all": "مشاهدة الكل",
      // Users 
      "Code":"الكود",
      "Email Verified At":"تم التحقق من البريد الإلكتروني في",
      "Not yet":"ليس بعد",
      "Image":"الصورة",
      "Device Token":"رمز الجهاز",
      "Points Wallet": "محفظة النقاط",
      "Search by customer name": "البحث حسب اسم العميل",
            // DriversData
      "Add new driver":"إضافة سائق جديد",
      "Search by driver name":"البحث حسب اسم السائق",
      "Laundry id":"الرقم التسلسلي للمغسلة",
      "No drivers available": "لا يوجد سائقين متاحين",
      // AddNewDriver
      "Add New Driver":"إضافة سائق جديد",
      "Password Confirmation":"تأكيد كلمة المرور",
      // UpdateDriver 
      "Update Driver":"تعديل معلومات السائق",
      "Image": "الصورة",
      // CarsData
      "Add new car":"أضف سيارة جديدة",
      "Search by car number":"البحث عن طريق رقم السيارة",
      "Car number": "رقم السيارة",
      // AddNewCar 
      "Add New Car":"أضف سيارة جديدة",
      "Laundry":"المغسلة",
      "Select laundry":"اختر المغسلة",
      "Driver":"السائق",
      "Select driver": "حدد السائق",
      // UpdateCar
      "Update Car": "تحديث السيارة",
      // OrderData 
      "Add new order":"إضافة طلب جديد",
      "Search by order number":"البحث حسب رقم الطلب",
      "Number": "الرقم",
      "Source": "المصدر",
      "Owner":"المالك",
      // OrderDetails
      "Order Details":"تفاصيل الطلب",
      "Type":"النوع",
      "Item":"غرض",
      "Image":"الصورة",
      "Quantity":"الكمية",
      "Sub Total Price":"السعر الإجمالي الفرعي",
      "Service Id": "الرقم التسلسلي للخدمة",
      // ProductsData
      "English Name":"الاسم الانجليزي",
      "Arabic Name":"الاسم العربي",
      "Details":"تفاصيل",
      "Urgent Price":"السعر المباشر",
      "Indirect Price": "السعر غير المباشر",
      // Notifications
      "Title":"العنوان",
      "Body":"المحتوى",
      "Order Id":"الرقم التسلسلي للطلب",
      "Read at":"وقت القراءة",
      "No notifications available":"لا توجد إشعارات متاحة",
      // Advertisements
      "Add new advertisement":"إضافة إعلان جديد",
      "All Status":"جميع الحالات",
      "All Laundries":"جميع المغاسل",
      "Search by advretisement name":"البحث حسب اسم الإعلان",
      "Laundry Name":"اسم المغسلة",
      "Points": "النقاط",
            // AddNewAdvertisement
      "Add new advertisement":"إضافة إعلان جديد",
      "Choose A Laundry":"اختر المغسلة",
      // UpdateAdvertisement
              "Update advertisement": "تحديث الإعلان",
      // Admins 
      "Search by admin name":"البحث حسب اسم المسؤول",
      // UserById 
      "Customer Addresses":"عنوان العميل",
      "Country":"البلد",
      "City":"المدينة",
      "Postal Code":"الرمز البريدي",
      "Full Name": "الاسم",
      "Contact Number" : "رقم التواصل",
      "Address Line 1":"العنوان الأول",
      "Address Line 2":"العنوان الثاني",
              "Customer Orders": "طلبات العميل",
      // AddNewItem 
              "Add new item":"إضافة منتج جديد",
              "Add New Item": "إضافة منتج جديد",
            //   UpdateItem 
              "Update Item": "تحديث المنتج",
            //   Services 
            "Add new service":"إضافة خدمة جديدة",
            "Add New Service":"إضافة خدمة جديدة",
            // UpdateService
                  "Update Service": "تحديث الخدمة",
        //     Reports
                  "Start Date": "تاريخ البداية",
                  "End Date": "تاريخ الانتهاء",
        "Download":"تحميل",
    }
  },
};

const lng = localStorage.getItem("dpLanguage") || "English";
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
