import React, { useEffect, useState } from "react";
import {
  fetchLaundryById,
  updateLaundry,
  updateLaundryMedia,
} from "../Api/Laundry";
import { fetchLaundryItems } from "../Api/Items";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { lng } from "../Api/api";
import { useDashboard } from "../Context/DashboardContext";
import { usePopup } from "../Context/PopupContext";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { fetchServices } from "../Api/Services";
import BreadcrumbPath from "../Components/BreadcrumbPath";
function UpdateLaundry() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const [showPicOptions, setShowPicOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    phoneNumber: "",
    city: "",
    addressLine: "",
    lat: "",
    lng: "",
    undirectItems: [],
    urgentItems: [],
    imgs: [],
  });
  const { updateLaundryPics, setUpdateLaudriesPics } = useDashboard();
  const { setAction, setType, setItemId, setMakeDecisionOpen } = usePopup();
  const { id } = useParams();

  const getLaundryData = async () => {
    try {
      setDataLoading(true);
      const response = await fetchLaundryById(id);
      console.log(response);
      setData((prev) => ({
        ...prev,
        nameEn: response?.data?.name_en,
        nameAr: response?.data?.name_ar,
        descriptionEn: response?.data?.description_en,
        descriptionAr: response?.data?.description_ar,
        phoneNumber: response?.data?.phone_number,
        undirectItems: response?.data?.details?.map((item) => ({
          laundry_item_id: item?.item.id,
          services: item?.services?.map((service) => ({
            service_id: service?.service?.id,
            price: parseFloat(service?.prices[1]?.join("")),
            order_type: 2,
          })),
        })),
        urgentItems:
          (response?.data?.urgent === 1 &&
            response?.data?.details?.map((item) => ({
              laundry_item_id: item?.item.id,
              services: item?.services?.map((service) => ({
                service_id: service?.service?.id,
                price: parseFloat(service?.prices[0]?.join("")),
                order_type: 1,
              })),
            }))) ||
          [],
        phoneNumber: response?.data?.phone_number,
        city: response?.data?.addresses?.city,
        addressLine: response?.data?.addresses?.address_line_1,
        lat: response?.data?.addresses?.lat,
        lng: response?.data?.addresses?.lng,
        imgs: response?.data?.media?.map((item) => ({
          id: item.id,
          url: item.url_image,
        })),
      }));
    } catch (err) {
      console.error("get laundry data by id err : ", err);
    } finally {
      setDataLoading(false);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleImageUpload = async (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    const formData = new FormData();
    newFiles.forEach((file, index) => {
      formData.append(`url_media[${index}]`, file);
    });
    try {
      const response = await updateLaundryMedia(id, formData);
      console.log(response);
      if (response.success === true) {
        toastSuccess(response.message);
        setUpdateLaudriesPics((prev) => prev + 1);
      }
    } catch (err) {
      console.error("update ad pics err : ", err);
    }
  };

  const handleItemChange = (e, itemId, type) => {
    const isChecked = e.target.checked;
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = isChecked
        ? [...prevData[key], { laundry_item_id: itemId, services: [] }]
        : prevData[key].filter((item) => item.laundry_item_id !== itemId);

      return { ...prevData, [key]: updatedItems };
    });
  };

  const handlePriceChange = (itemId, serviceId, newPrice, type) => {
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = prevData[key].map((item) => {
        if (item.laundry_item_id === itemId) {
          const updatedServices = item.services.map((service) =>
            service.service_id === serviceId
              ? { ...service, price: newPrice, order_type: type === 1 ? 1 : 2 }
              : service
          );
          return { ...item, services: updatedServices };
        }
        return item;
      });

      return { ...prevData, [key]: updatedItems };
    });
  };

  const handleServiceChange = (e, serviceId, itemId, type) => {
    const isChecked = e.target.checked;
    const key = type === 1 ? "urgentItems" : "undirectItems";

    setData((prevData) => {
      const updatedItems = prevData[key].map((item) => {
        if (item.laundry_item_id === itemId) {
          const updatedServices = isChecked
            ? item.services.some(
                (service) =>
                  service.service_id === serviceId && service.item_id === itemId
              )
              ? item.services
              : [
                  ...item.services,
                  { service_id: serviceId, item_id: itemId, price: 0 },
                ]
            : item.services.filter(
                (service) =>
                  !(
                    service.service_id === serviceId &&
                    service.item_id === itemId
                  )
              );

          return { ...item, services: updatedServices };
        }
        return item;
      });

      return { ...prevData, [key]: updatedItems };
    });
  };

  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);

  const getLaundryItems = async () => {
    try {
      setItemsLoading(true);
      const response = await fetchLaundryItems(0);
      console.log(response);
      setItems(response.data);
    } catch (err) {
      console.error("get laundry items err : ", err);
    } finally {
      setItemsLoading(false);
    }
  };
  const getServices = async () => {
    try {
      setServicesLoading(true);
      const response = await fetchServices(0);
      console.log(response);
      setServices(response.data);
    } catch (err) {
      console.error("get services err : ", err);
    } finally {
      setServicesLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const laundryData = {
      name_en: data.nameEn,
      name_ar: data.nameAr,
      description_en: data.descriptionEn,
      description_ar: data.descriptionAr,
      phone_number: data.phoneNumber,
      city: data.city,
      address_line_1: data.addressLine,
      lat: data.lat,
      lng: data.lng,
      array_ids: [...data.undirectItems, ...data.urgentItems]?.flatMap((item) =>
        item.services.map((service) => ({
          laundry_item_id: item.laundry_item_id,
          price: service.price,
          service_id: service.service_id,
          order_type_id: service.order_type,
        }))
      ),
    };
    const formData = new FormData();

    // Append laundryData to formData
    Object.entries(laundryData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          Object.entries(item).forEach(([subKey, subValue]) => {
            formData.append(`${key}[${index}][${subKey}]`, subValue);
          });
        });
      } else {
        formData.append(key, value);
      }
    });

    // Append image data (handle multiple images)
    data.imgs.forEach((img, index) => {
      formData.append(`array_url[${index}][url_image]`, img);
    });

    try {
      setLoading(true);
      const response = await updateLaundry(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/laundries";
      }
    } catch (err) {
      console.error("add new laundry err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLaundryData();
  }, [updateLaundryPics]);
  useEffect(() => {
    getLaundryItems();
    getServices();
  }, [lng]);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Laundries"), url: "/dashboard/laundries" },
    { name: `${t("Laundry")} ${id}`, url: `/dashboard/laundries/${id}` },
    { name: `${t("Update laundry")}`, url: `/updateLaundry` },
  ];

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="font-[500]">{t("Update laundry")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEn"
            >
              {t("Name In English")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameEn}
              name="nameEn"
              onChange={changeHandler}
              id="nameEn"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameAr"
            >
              {t("Name In Arabic")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.nameAr}
              name="nameAr"
              onChange={changeHandler}
              id="nameAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionEn"
            >
              {t("Description In English")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <textarea
              value={data.descriptionEn}
              name="descriptionEn"
              onChange={changeHandler}
              id="descriptionEn"
              className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
              placeholder={t("Description In English")}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionAr"
            >
              {t("Description In Arabic")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            <textarea
              value={data.descriptionAr}
              name="descriptionAr"
              onChange={changeHandler}
              id="descriptionAr"
              className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
              placeholder={t("Description In Arabic")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="phoneNumber"
            >
              {t("Phone Number")} <span className="text-red-600">*</span> :
            </label>
            <input
              type="text"
              pattern="[0-9]+"
              value={data.phoneNumber}
              name="phoneNumber"
              onChange={changeHandler}
              id="phoneNumber"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("ex: 0963 654 656 65")}
            />
          </div>
        </div>
        <label className="text-[13px] flex items-center gap-1">
          {`${t("Undirect Orders")}`}
          <span className="text-red-600">*</span> :
        </label>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="items"
          >
            {t("Select Items")} <span className="text-red-600">*</span> :
          </label>
          {itemsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div id="items" className="flex flex-col gap-2 text-[13px] w-full">
              {items.map((item) => {
                const selectedItem =
                  data?.undirectItems?.length > 0 &&
                  data?.undirectItems?.find(
                    (selectedItem) => selectedItem.laundry_item_id === item.id
                  );
                const isChecked = Boolean(selectedItem);

                return (
                  <div
                    className="text-MainText bg-white flex flex-col gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`item-${item.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) => handleItemChange(e, item.id, 2)}
                      />
                      <label htmlFor={`item-${item.id}`}>
                        {lng === "Arabic"
                          ? item.item_type_ar
                          : item.item_type_en}
                      </label>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className="text-[13px] flex items-center gap-1 cursor-pointer"
                        htmlFor={`services${item.id}`}
                      >
                        {t("Select Services")}
                        <span className="text-red-600">*</span> :
                      </label>
                      {servicesLoading ? (
                        <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
                      ) : (
                        <div
                          id={`services${item.id}`}
                          className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
                        >
                          {services.map((service) => {
                            const isServiceSelected =
                              selectedItem?.services?.some(
                                (s) => s.service_id === service.id
                              );
                            const price =
                              selectedItem?.services?.find(
                                (s) => s.service_id === service.id
                              )?.price || "";
                            return (
                              <div
                                className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                                key={service.id}
                              >
                                <div className="flex items-center gap-2">
                                  <input
                                    id={`service-${service.id}`}
                                    type="checkbox"
                                    className="focus:ring-0"
                                    checked={isServiceSelected}
                                    disabled={!isChecked}
                                    onChange={(e) =>
                                      handleServiceChange(
                                        e,
                                        service.id,
                                        item.id,
                                        2
                                      )
                                    }
                                  />
                                  <label htmlFor={`service-${service.id}`}>
                                    {lng === "Arabic"
                                      ? service.name_ar
                                      : service.name_en}
                                  </label>
                                </div>
                                <div className="flex items-center gap-2">
                                  <label htmlFor={`price-${service.id}`}>
                                    {t("Price")}
                                  </label>
                                  <input
                                    className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none"
                                    id={`price-${service.id}`}
                                    type="number"
                                    value={price}
                                    disabled={!isServiceSelected} // Disable if the item is not checked
                                    onChange={(e) =>
                                      handlePriceChange(
                                        item.id,
                                        service.id,
                                        parseFloat(e.target.value),
                                        2
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <label className="text-[13px] flex items-center gap-1">
          {`${t("Urgent Orders")}`}
          <span className="text-red-600">*</span> :
        </label>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="items"
          >
            {t("Select Items")} <span className="text-red-600">*</span> :
          </label>
          {itemsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div id="items" className="flex flex-col gap-2 text-[13px] w-full">
              {items.map((item) => {
                const selectedItem =
                  data?.urgentItems?.length > 0 &&
                  data?.urgentItems?.find(
                    (selectedItem) => selectedItem.laundry_item_id === item.id
                  );
                const isChecked = Boolean(selectedItem);

                return (
                  <div
                    className="text-MainText bg-white flex flex-col gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`item-${item.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) => handleItemChange(e, item.id, 1)}
                      />
                      <label htmlFor={`item-${item.id}`}>
                        {lng === "Arabic"
                          ? item.item_type_ar
                          : item.item_type_en}
                      </label>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label
                        className="text-[13px] flex items-center gap-1 cursor-pointer"
                        htmlFor={`services${item.id}`}
                      >
                        {t("Select Services")}
                        <span className="text-red-600">*</span> :
                      </label>
                      {servicesLoading ? (
                        <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
                      ) : (
                        <div
                          id={`services${item.id}`}
                          className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
                        >
                          {services.map((service) => {
                            const isServiceSelected =
                              selectedItem?.services?.some(
                                (s) => s.service_id === service.id
                              );
                            const price =
                              selectedItem?.services?.find(
                                (s) => s.service_id === service.id
                              )?.price || "";
                            return (
                              <div
                                className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                                key={service.id}
                              >
                                <div className="flex items-center gap-2">
                                  <input
                                    id={`service-${service.id}`}
                                    type="checkbox"
                                    className="focus:ring-0"
                                    checked={isServiceSelected}
                                    disabled={!isChecked}
                                    onChange={(e) =>
                                      handleServiceChange(
                                        e,
                                        service.id,
                                        item.id,
                                        1
                                      )
                                    }
                                  />
                                  <label htmlFor={`service-${service.id}`}>
                                    {lng === "Arabic"
                                      ? service.name_ar
                                      : service.name_en}
                                  </label>
                                </div>
                                <div className="flex items-center gap-2">
                                  <label htmlFor={`price-${service.id}`}>
                                    {t("Price")}
                                  </label>
                                  <input
                                    className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none"
                                    id={`price-${service.id}`}
                                    type="number"
                                    value={price}
                                    disabled={!isServiceSelected} // Disable if the item is not checked
                                    onChange={(e) =>
                                      handlePriceChange(
                                        item.id,
                                        service.id,
                                        parseFloat(e.target.value),
                                        1
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-3">
          <label className="text-[13px] flex items-center gap-1">
            {`${t("Address")}`}
            <span className="text-red-600">*</span> :
          </label>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`city`}
              >
                {t("City")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={data.city}
                onChange={changeHandler}
                name="city"
                id={`city`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("City")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`addressLine`}
              >
                {t("Address Line")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={data.addressLine}
                onChange={changeHandler}
                name="addressLine"
                id={`addressLine`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address Line")}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`lat`}
              >
                {t("Lat")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={data.lat}
                onChange={changeHandler}
                name="lat"
                id={`lat`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lat")}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor={`lng`}
              >
                {t("Lng")} <span className="text-red-600">*</span> :
              </label>
              <input
                type="number"
                value={data.lng}
                onChange={changeHandler}
                name="lng"
                id={`lng`}
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Lng")}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="img"
          >
            {t("Images")} <span className="text-red-600">*</span> :
          </label>
          <input
            multiple
            onChange={handleImageUpload}
            type="file"
            id="img"
            className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            placeholder={t("Images")}
          />
        </div>
        {data.imgs.length > 0 && (
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white w-fit">
            {data.imgs.map((file, index) => (
              <div
                onMouseOver={() =>
                  setShowPicOptions((prevState) => ({
                    ...prevState,
                    [index]: true,
                  }))
                }
                onMouseLeave={() =>
                  setShowPicOptions((prevState) => ({
                    ...prevState,
                    [index]: false,
                  }))
                }
                key={index}
                className="w-full relative max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[200px]"
              >
                <div
                  className={`${
                    showPicOptions[index] ? "md:block" : "md:hidden"
                  } animate-flashing absolute left-0 top-0 w-full h-full bg-black/25 max-md:bg-transparent`}
                >
                  <div
                    onClick={() => {
                      setType("laundry media");
                      setAction("delete");
                      setItemId(file.id);
                      setMakeDecisionOpen(true);
                    }}
                    className="mt-2 mx-2 w-fit cursor-pointer text-MainText flex justify-center items-center rounded-full p-2 bg-[#d8d7d7] hover:bg-[#5f5f5f] hover:text-white duration-300"
                  >
                    <AiOutlineClose size={15} />
                  </div>
                </div>
                <img
                  className="w-full h-full object-cover"
                  key={index}
                  src={file.url}
                  alt={`image ${index}`}
                />
              </div>
            ))}
          </div>
        )}
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Update")
          )}
        </button>
      </div>
    </form>
  );
}

export default UpdateLaundry;
