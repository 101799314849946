import { apiAdmin } from "./api";

export const fetchServices = async (page,per_page) => {
  try {
    const response = await apiAdmin.get(`/api/Service?page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    console.log("fetch services err : ", error);
  }
};

export const addService = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/service`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log("add service err : ", error);
  }
};
// name_en
// name_ar

export const updateService = async (id,data) => {
  try {
    const response = await apiAdmin.post(`/api/update-service?id=${id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log("update service err : ", error);
  }
};

export const deleteService = async (id) => {
  try {
    const response = await apiAdmin.delete(`/api/delete-service?id=${id}`);
    return response.data;
  } catch (error) {
    console.log("delete service item err : ", error);
  }
};

export const getServiceById = async (id) => {
  try {
    const response = await apiAdmin.get(`/api/service-id?id=${id}`);
    return response.data;
  } catch (error) {
    console.log("get service by id err : ", error);
  }
};
