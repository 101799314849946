import React from "react";

function CustomOption({ value, content, hide, key, className }) {
  return (
    <option
      className={`${className} bg-white text-MainText`}
      key={key}
      value={value}
      disabled={hide ? true : false}
    >
      {content}
    </option>
  );
}

export default CustomOption;
