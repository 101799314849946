import { apiAdmin } from "./api";

export const fetchLaundryItems = async (page,per_page) => {
  try {
    const response = await apiAdmin.get(`/api/getLaundryItem?page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    console.log("fetch laundry items err : ", error);
  }
};

export const addLaundryItem = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/laundryItem`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log("add laundry item err : ", error);
  }
};

export const updateLaundryItem = async (id,data) => {
  try {
    const response = await apiAdmin.post(`/api/update-laundryItem?id=${id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log("update laundry item err : ", error);
  }
};

export const deleteLaundryItem = async (id) => {
  try {
    const response = await apiAdmin.delete(`/api/delete-laundryItem?id=${id}`);
    return response.data;
  } catch (error) {
    console.log("delete laundry item err : ", error);
  }
};

export const getLaundryItemById = async (id) => {
  try {
    const response = await apiAdmin.get(`/api/getLaundryItem-id?id=${id}`);
    return response.data;
  } catch (error) {
    console.log("get laundry item by id err : ", error);
  }
};
