import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Components/Pagination";
import {
  changeAdvertisementStatus,
  fetchAdvertisements,
} from "../Api/Advertisments";
import { useDashboard } from "../Context/DashboardContext";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import CustomSlider from "../Components/CustomSlider";
import { SwiperSlide } from "swiper/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { IoMdClock } from "react-icons/io";
import CustomSelect from "../Components/CustomSelect";
import CustomOption from "../Components/CustomOption";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function Advertisements() {
  const { t } = useTranslation();
  const {
    updateAds,
    setUpdateAds,
    paginationKey,
    setPaginationKey,
    laundries,
    getLaundriesData,
  } = useDashboard();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [advertisements, setAdvertisements] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [activeStatus, setActiveStatus] = useState("");
  const [selectedLaundryId, setSelectedLaundryId] = useState("");
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);

  const search = async () => {
    getAdvertisementsData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getAdvertisementsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAdvertisements(
        activeStatus,
        selectedLaundryId,
        query,
        currentPage
      );
      console.log(response);
      setAdvertisements(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };
  const handleChangeStatus = async (id, status) => {
    try {
      setUpdateAds((prev) => prev + 1);
      const response = await changeAdvertisementStatus(id, status);
      console.log(response);
    } catch (err) {
      console.error("change ad status err : ", err);
    }
  };

  useEffect(() => {
    getAdvertisementsData();
  }, [updateAds, selectedLaundryId, activeStatus, currentPage]);
  useEffect(() => {
    getLaundriesData(0);
  }, []);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Advertisements"), url: "/advertisements" },
  ];

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="flex md:items-center max-md:flex-col gap-3">
        {!isSuper && (
          <Link
            to={`addNewAdvertisement`}
            className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[157px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
          >
            {t("Add new advertisement")}
          </Link>
        )}
        <CustomSelect
          value={activeStatus}
          onChange={(e) => {
            setActiveStatus(e.target.value);
            setPaginationKey((prev) => prev + 1);
          }}
          className={`md:min-w-[200px] md:max-w-[200px]`}
          children={
            <>
              <CustomOption content={t("All Status")} value="" />
              <CustomOption
                content={t("pending")}
                value="0"
                className="capitalize"
              />
              <CustomOption
                content={t("confirmed")}
                value="1"
                className="capitalize"
              />
              <CustomOption
                content={t("cancelled")}
                value="2"
                className="capitalize"
              />
            </>
          }
        />
        {isSuper && (
          <CustomSelect
            value={selectedLaundryId}
            onChange={(e) => {
              setSelectedLaundryId(e.target.value);
              setPaginationKey((prev) => prev + 1);
            }}
            children={
              <>
                <CustomOption content={t("All Laundries")} value="" />
                {laundries.map((laundry) => (
                  <CustomOption
                    content={
                      lng === "Arabic"
                        ? laundry?.laundry?.name_ar
                        : laundry?.laundry?.name_en
                    }
                    value={laundry?.laundry?.id}
                    key={laundry?.laundry?.id}
                  />
                ))}
              </>
            }
          />
        )}
        <SearchInput
          query={query}
          setQuery={setQuery}
          search={search}
          placeHolder={t("Search by advretisement name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("English Name")} />
              <MainHeadTableItem
                content={t("Arabic Name")}
                className={`max-md:hidden`}
              />
              {isSuper && (
                <MainHeadTableItem
                  content={t("Laundry Name")}
                  className={`max-md:hidden`}
                />
              )}
              <MainHeadTableItem
                content={t("Image")}
                className={`max-md:hidden w-[125px]`}
              />
              <MainHeadTableItem
                content={t("Points")}
                className={`max-md:hidden w-[80px]`}
              />
              <MainHeadTableItem
                content={t("Start Date")}
                className={`max-md:hidden w-[110px]`}
              />
              <MainHeadTableItem
                content={t("End Date")}
                className={`max-md:hidden w-[110px]`}
              />
              <MainHeadTableItem
                content={t("Status")}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem
                content={`...`}
                className={`max-md:hidden w-[100px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 5 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 10 : 9 }).map(
                    (_, inIndex) => (
                      <td
                        key={inIndex}
                        className={`${inIndex > 2 && "max-md:hidden"}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[100px] max-md:h-[50px]"
                        />
                      </td>
                    )
                  )}
                </tr>
              ))
            ) : advertisements.length > 0 ? (
              advertisements.map((advertisement) => (
                <>
                  <tr key={advertisement.id} className="">
                    <MainBodyTableItem
                      content={advertisement.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={advertisement.name_en} />
                    <MainBodyTableItem
                      content={advertisement.name_ar}
                      className={`max-md:hidden`}
                    />
                    {isSuper && (
                      <MainBodyTableItem
                        content={advertisement.laundry.name_en}
                        className={`max-md:hidden`}
                      />
                    )}
                    <MainBodyTableItem
                      content={
                        <CustomSlider>
                          {advertisement?.media.map((pic) => (
                            <SwiperSlide key={pic.id}>
                              <img
                                className={`w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover`}
                                src={pic.url_image}
                                alt=""
                              />
                            </SwiperSlide>
                          ))}
                        </CustomSlider>
                      }
                      className={`max-md:hidden w-[125px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={advertisement.points}
                      className={`max-md:hidden w-[80px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={advertisement.start_date}
                      className={`max-md:hidden w-[110px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={advertisement.end_date}
                      className={`max-md:hidden w-[110px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={t(advertisement.status)}
                      className={`max-md:hidden w-[100px] overflow-x-auto`}
                    />
                    <MainBodyTableItem
                      content={
                        !isSuper ? (
                          <Link
                            to={`${advertisement.id}/updateAdvertisement`}
                            className={`cursor-pointer flex justify-center items-center`}
                          >
                            <AiOutlineEdit
                              className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                        ) : advertisement.status === "pending" ? (
                          <div className="flex justify-center items-center gap-1 mx-auto">
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 1)
                              }
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              {/* {t("Confirm")} */}
                              <IoCheckmark
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </button>
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 2)
                              }
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              {/* {t("Delay")} */}
                              <IoMdClock
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </button>
                            <Link
                              to={`${advertisement.id}/updateAdvertisement`}
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              <AiOutlineEdit
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center gap-1 mx-auto">
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 0)
                              }
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              {/* {t("Request")} */}
                              <IoClose className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                            </button>
                            <Link
                              to={`${advertisement.id}/updateAdvertisement`}
                              className={`cursor-pointer flex justify-center items-center`}
                            >
                              <AiOutlineEdit
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                          </div>
                        )
                      }
                      className={`max-md:hidden w-[100px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(advertisement.id)}
                        >
                          {moreOpen[advertisement.id] ? (
                            <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                          ) : (
                            <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                          )}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[advertisement.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={advertisement.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            lng === "Arabic"
                              ? advertisement.name_ar
                              : advertisement.name_en
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Laundry Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={advertisement?.laundry?.name_en}
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Image")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <CustomSlider>
                              {advertisement?.media.map((pic) => (
                                <SwiperSlide key={pic.id}>
                                  <img
                                    className={`w-[100px] h-[100px] max-w-[100px] max-h-[100px] mx-auto object-cover`}
                                    src={pic.url_image}
                                    alt=""
                                  />
                                </SwiperSlide>
                              ))}
                            </CustomSlider>
                          }
                        />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Points")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={advertisement.points} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Start Date")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={advertisement.start_date} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("End Date")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={advertisement.end_date} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Status")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={t(advertisement.status)} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={`...`}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            !isSuper ? (
                              <Link
                                to={`${advertisement.id}/updateAdvertisement`}
                                className={`cursor-pointer flex justify-center items-center`}
                              >
                                <AiOutlineEdit
                                  className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                />
                              </Link>
                            ) : advertisement.status === "pending" ? (
                              <div className="flex justify-center items-center gap-1 mx-auto">
                                <button
                                  onClick={() =>
                                    handleChangeStatus(advertisement.id, 1)
                                  }
                                  className={`cursor-pointer flex justify-center items-center`}
                                >
                                  {/* {t("Confirm")} */}
                                  <IoCheckmark
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </button>
                                <button
                                  onClick={() =>
                                    handleChangeStatus(advertisement.id, 2)
                                  }
                                  className={`cursor-pointer flex justify-center items-center`}
                                >
                                  {/* {t("Delay")} */}
                                  <IoMdClock
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </button>
                                <Link
                                  to={`${advertisement.id}/updateAdvertisement`}
                                  className={`cursor-pointer flex justify-center items-center`}
                                >
                                  <AiOutlineEdit
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </Link>
                              </div>
                            ) : (
                              <div className="flex justify-center items-center gap-1 mx-auto">
                                <button
                                  onClick={() =>
                                    handleChangeStatus(advertisement.id, 0)
                                  }
                                  className={`cursor-pointer flex justify-center items-center`}
                                >
                                  {/* {t("Request")} */}
                                  <IoClose className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                                </button>
                                <Link
                                  to={`${advertisement.id}/updateAdvertisement`}
                                  className={`cursor-pointer flex justify-center items-center`}
                                >
                                  <AiOutlineEdit
                                    className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                                  />
                                </Link>
                              </div>
                            )
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No admins available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          key={paginationKey}
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Advertisements;
