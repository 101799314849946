import React, { useEffect, useState } from "react";
import { fetchCarById, updateCar } from "../Api/Cars";
import { useParams } from "react-router-dom";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { isSuper } from "../Api/api";
import { laundryByAdmin } from "../Api/Laundry";
import { fetchDrivers } from "../Api/Drivers";
import CustomSelect from "../Components/CustomSelect";
import { useDashboard } from "../Context/DashboardContext";
import CustomOption from "../Components/CustomOption";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function UpdateCar() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { selectedLanguage } = useDashboard();
  const laundryId = localStorage.getItem("laundryId");
  const [laundries, setLaundries] = useState([]);
  const [laundriesLoading, setLaundriesLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driversLoading, setDriversLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    laundryId: laundryId || "",
    driverId: "",
    carNumber: "",
  });
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (
      !(charCode >= 48 && charCode <= 57) &&
      charCode !== 45 // ASCII code for hyphen
    ) {
      e.preventDefault();
      return false;
    }
  };
  const getCarById = async () => {
    try {
      setDataLoading(true);
      const response = await fetchCarById(id);
      console.log(response);
      setData({
        ...data,
        laundryId: response.data.laundry_id,
        driverId: response.data.driver_id,
        carNumber: response.data.number_car,
      });
    } catch (err) {
      console.error("get car by id err : ", err);
    } finally {
      setDataLoading(false);
    }
  };
  const getLaundriesData = async () => {
    try {
      setLaundriesLoading(true);
      const response = await laundryByAdmin(0);
      console.log(response.data);
      setLaundries(response.data.laundries);
    } catch (err) {
      console.error("get laundry data err : ", err);
    } finally {
      setLaundriesLoading(false);
    }
  };
  const getDriversData = async () => {
    try {
      setDriversLoading(true);
      const response = await fetchDrivers(0);
      console.log(response);
      setDrivers(response.data);
    } catch (err) {
      console.error("get drivers data err : ", err);
    } finally {
      setDriversLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("laundry_id", data.laundryId);
    formData.append("driver_id", data.driverId);
    formData.append("number_car", data.carNumber);
    try {
      setLoading(true);
      const response = await updateCar(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/cars";
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update car err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLaundriesData(0);
    getDriversData(0);
    getCarById();
  }, []);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Cars"), url: "/dashboard/cars" },
    { name: `${t("Update Car")} ${id}`, url: "/updateCar" },
  ];

  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="font-[500]">{t("Update Car")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          {isSuper && (
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="laundry"
              >
                {t("Laundry")} <span className="text-red-600">*</span> :
              </label>
              {laundriesLoading ? (
                <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
              ) : (
                <CustomSelect
                  className="w-full"
                  value={data.laundryId}
                  name="laundryId"
                  onChange={changeHandler}
                  id="laundry"
                  children={
                    <>
                      <CustomOption
                        content={t("Select laundry")}
                        value=""
                        hide={true}
                      />
                      {laundries.map((laundry) => (
                        <CustomOption
                          content={
                            selectedLanguage === "Arabic"
                              ? laundry?.laundry?.name_ar
                              : laundry?.laundry?.name_en
                          }
                          value={laundry?.laundry?.id}
                          key={laundry?.laundry?.id}
                        />
                      ))}
                    </>
                  }
                />
              )}
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="drivers"
            >
              {t("Driver")} <span className="text-red-600">*</span> :
            </label>
            {driversLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <CustomSelect
                className="w-full"
                value={data.driverId}
                name="driverId"
                onChange={changeHandler}
                id="drivers"
                children={
                  <>
                    <CustomOption
                      content={t("Select driver")}
                      value=""
                      hide={true}
                    />
                    {drivers.map((driver) => (
                      <CustomOption
                        content={driver?.user?.name}
                        value={driver.id}
                        key={driver.id}
                      />
                    ))}
                  </>
                }
              />
            )}
          </div>
        </div>
        {dataLoading ? (
          <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
        ) : (
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="carNumber"
            >
              {t("Car number")} <span className="text-red-600">*</span> :
            </label>
            <input
              value={data.carNumber}
              name="carNumber"
              onChange={changeHandler}
              onKeyPress={(e) => handleKeyPress(e)}
              id="carNumber"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Car number")}
            />
          </div>
        )}
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Update")
        )}
      </button>
    </form>
  );
}

export default UpdateCar;
