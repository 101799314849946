import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchCustomerById } from "../Api/Users";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import OutterTable from "../Components/OutterTable";
import { useTranslation } from "react-i18next";
import { usePopup } from "../Context/PopupContext";
import Skeleton from "react-loading-skeleton";
import InnerTable from "../Components/InnerTable";
import BreakDiv from "../Components/BreakDiv";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import BreadcrumbPath from "../Components/BreadcrumbPath";

function UserById() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setMakeDecisionOpen, setAction, setType, setItemId } = usePopup();
  const [moreOpen, setMoreOpen] = useState({});
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetchCustomerById(id);
      console.log(response.data);
      setData(response.data);
    } catch (err) {
      console.error("get customer by id err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const breadcrumbPaths = [
    { name: t("Home"), url: "/dashboard" },
    { name: t("Customers"), url: "/dashboard/users" },
    { name: `${t("Customer")} ${id}`, url: `${id}` },
  ];

  return (
    <div className="flex flex-col gap-3">
      <BreadcrumbPath paths={breadcrumbPaths} />
      <div className="font-[500]">{t("Customer Addresses")} : </div>
      {data?.addresses?.length > 0 ? (
        <>
          <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] max-md:hidden">
            <table
              className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
            >
              <thead>
                <tr>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Name")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Phone Number")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Country")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("City")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Postal Code")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Address Line 1")}
                  </th>
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                    {t("Address Line 2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    {Array.from({ length: 7 }).map((_, index) => (
                      <td
                        key={index}
                        className={`${index < 1 && "max-md:hidden"}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.full_name}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.contact_number}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.country}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.city}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.postcode}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.address_line_1}
                      </td>
                      <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                        {data?.addresses[0]?.address_line_2}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] md:hidden">
            <table
              className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
            >
              <tbody>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Full Name")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.full_name
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Contact Number")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.contact_number
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Country")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.country
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("City")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.city
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Postal Code")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.postcode
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Address Line 1")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.address_line_1
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {t("Address Line 2")}
                  </td>
                  <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    ) : (
                      data?.addresses[0]?.address_line_2
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="whitespace-nowrap p-[12px] text-[13px] bg-white">
          {t("No address available")}
        </div>
      )}
      <BreakDiv />
      <div className="font-[500]">{t("Customer Orders")} : </div>
      <OutterTable>
        <thead>
          <tr>
            <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
            <MainHeadTableItem
              content={t("Order Number")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Order Source")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Status")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Base Cost")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem className={`md:hidden w-[70px]`} />
            <MainHeadTableItem
              content={t("Note")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Order Date")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Pickup Time")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem
              content={t("Delivery Time")}
              className={`max-md:hidden`}
            />
            <MainHeadTableItem content={"..."} className={`max-md:hidden`} />
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: 10 }).map((_, outIndex) => (
              <tr key={outIndex}>
                {Array.from({ length: 10 }).map((_, inIndex) => (
                  <td
                    key={inIndex}
                    className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                  >
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ))
          ) : data.orders.length > 0 ? (
            data.orders.map((order) => (
              <>
                <tr key={order?.id} className="">
                  <MainBodyTableItem
                    content={order?.id}
                    className={`w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={order?.order_number}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={order?.type_order}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={order?.status}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={`${parseFloat(order?.base_cost).toFixed(2)} $`}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(order?.id)}
                      >
                        {moreOpen[order?.id] ? (
                          <MdKeyboardArrowUp className="mx-auto text-[20px]" />
                        ) : (
                          <MdKeyboardArrowDown className="mx-auto text-[20px]" />
                        )}
                      </span>
                    }
                    className={`md:hidden w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={order?.note}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={order?.order_date}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={order?.pickup_time}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={order?.delivery_time}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <div className="flex justify-center items-center mx-auto gap-1">
                        <Link
                          className={`${
                            order?.order_type?.type === "Urgent"
                              ? "block"
                              : "hidden"
                          } text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                          to={`${order?.id}/updateOrder`}
                        >
                          <AiOutlineEdit
                            className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                        <Link
                          to={`/dashboard/orders/${order?.id}`}
                          className={`text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                        >
                          <IoMdMore
                            className={`cursor-pointer mx-auto hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                        {order?.type_order === "web" && (
                          <div
                            onClick={() => {
                              setMakeDecisionOpen(true);
                              setAction("delete");
                              setType("order");
                              setItemId(order.id);
                            }}
                            className={`text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                          >
                            <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                          </div>
                        )}
                      </div>
                    }
                    className={`max-md:hidden`}
                  />
                </tr>
                <InnerTable openStatus={moreOpen[order?.id]}>
                  <tbody>
                    <tr>
                      <MainBodyTableItem
                        content={t("Id")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.id} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Order Source")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.type_order} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Order Owner")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={order?.address?.full_name || order?.user?.name}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Status")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.status} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Base Cost")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={`${parseFloat(order?.base_cost).toFixed(2)} $`}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Note")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.note} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Order Date")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.order_date} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Pickup Time")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.pickup_time} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Delivery Time")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={order?.delivery_time} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={"..."}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          <div className="flex justify-center items-center mx-auto gap-1">
                            <Link
                              className={`${
                                order?.order_type?.type === "Urgent"
                                  ? "block"
                                  : "hidden"
                              } text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                              to={`${order?.id}/updateOrder`}
                            >
                              <AiOutlineEdit
                                className={`cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                            <Link
                              to={`/dashboard/orders/${order?.id}`}
                              className={`text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                            >
                              <IoMdMore
                                className={`cursor-pointer mx-auto hover:text-HoverPrimaryColor duration-300`}
                              />
                            </Link>
                            {order?.type_order === "web" && (
                              <div
                                onClick={() => {
                                  setMakeDecisionOpen(true);
                                  setAction("delete");
                                  setType("order");
                                  setItemId(order.id);
                                }}
                                className={`text-[13px] cursor-pointer rounded-[0.25rem] flex justify-center items-center`}
                              >
                                <AiFillDelete className="cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300" />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </tr>
                  </tbody>
                </InnerTable>
              </>
            ))
          ) : (
            <div className="whitespace-nowrap p-[12px] text-[13px]">
              {t("No orders available")}
            </div>
          )}
        </tbody>
      </OutterTable>
    </div>
  );
}

export default UserById;
