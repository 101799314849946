import React from "react";
import { Link } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useDashboard } from "../Context/DashboardContext";
import { lng } from "../Api/api";

const BreadcrumbPath = ({ paths }) => {
  const { selectedLanguage } = useDashboard();
  return (
    <div className="flex items-center text-sm text-gray-600">
      {paths.map((path, index) => (
        <React.Fragment key={index}>
          {index > 0 &&
            (selectedLanguage === "Arabic" ? (
              <MdChevronLeft className="mx-2" />
            ) : (
              <MdChevronRight className="mx-2" />
            ))}
          {index === paths.length - 1 ? (
            <span className="font-semibold">{path.name}</span>
          ) : (
            <Link
              to={path.url}
              className="hover:text-PrimaryColor transition-colors duration-200"
            >
              {path.name}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BreadcrumbPath;
